import React,{useState,useRef,forwardRef} from 'react'
import { Route, BrowserRouter as Router, Routes, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import config from '../Config';

import { ContactUsService } from '../services/ContactUsService';

type FormData = {
  contact_type:string,
  company_name:string,
  full_name:string,
  email:string,
  phone_number:string,
  message:string
};

type FormErrors = {
  contact_type?:string,
  company_name?:string,
  full_name?:string,
  email?:string,
  phone_number?:string,
  message?:string,
  captcha?:string,
  common_err?:string,
  success?:string
};


export const ItSolutionSec = forwardRef((props: any, ref: React.Ref<HTMLDivElement>) => {

    const [formData, setFormData] = useState<FormData>({
        contact_type:'IT Solutions',
        company_name:'',
        full_name:'',
        email:'',
        phone_number:'',
        message:''
      });
    
      const [formErrors, setFormErrors] = useState<FormErrors>({});
      const [captchaValue, setCaptchaValue] = useState<string | null>(null);
      const captchaRef = useRef<ReCAPTCHA | null>(null);
    
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) : void => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
    
      const handleChange2 = (e: React.ChangeEvent<HTMLSelectElement>) : void => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
    
      const handleCaptchaChange = (value: string | null) => {
        setCaptchaValue(value);
        setFormErrors({captcha:''});
      };

      const validate = () => {
        const errors: FormErrors = {};
    
        if (!formData.contact_type) {
          errors.contact_type = 'Contact type is required';
        }
    
        if (!formData.company_name) {
          errors.company_name = 'Business name is required';
        } else if (!/^[0-9a-zA-Z\s]+$/.test(formData.company_name)) {
          errors.company_name = 'Business name is invalid';
        }
    
        if (!formData.full_name) {
          errors.full_name = 'Full name is required';
        } else if (!/^[a-zA-Z\s]+$/.test(formData.full_name)) {
          errors.full_name = 'Full name is invalid';
        }
    
        if (!formData.email) {
          errors.email = 'Email address is required';
        } else if (!/^[a-zA-Z][_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,10})$/.test(formData.email)) {
          errors.email = 'Email address is invalid';
        }
    
        if (!formData.phone_number) {
          errors.phone_number = 'Phone number is required';
        } else if (!/^[0-9\s]+$/.test(formData.phone_number) || formData.phone_number.length<10) {
          errors.phone_number = 'Phone number should be minimum 10 digits';
        }
        
        if (!formData.message) {
          errors.message = 'Message is required';
        } else if (!/^[0-9a-zA-Z.,\s]+$/.test(formData.message)) {
          errors.message = 'Message is invalid';
        }

        if (!captchaValue) {
          errors.captcha = 'Captcha is required';
        } else {
          ContactUsService.googleCaptchaVerify(captchaValue)
            .then((res) =>{
              console.log(res);
              // const capApiResponse:any = res.success;
              // if(capApiResponse){
    
              // }
            }
          )
          .catch((err) =>{
            errors.captcha = err;
            }
          );
        }
    
        setFormErrors(errors);
        return Object.keys(errors).length === 0; // Returns true if no errors
      };


    
      const handleSubmit =  (event:React.FormEvent<HTMLFormElement>) : void => {
        event.preventDefault();

        const submitBtnElement = document.getElementById("submitBtn");
        if (submitBtnElement) {
          submitBtnElement.classList.add("disabled");
        }

        if (captchaRef.current) {
          captchaRef.current.reset();
          setCaptchaValue(null);
        }
    
        if(validate()) {
    
          ContactUsService.sendContactFormData(formData)
          .then((res) =>{
                const apiResponse:any = res.data;
                if(apiResponse){
                  const response:any = apiResponse.response;
                  const apiCode:any = response.code;
                  const apiDescription:any = response.description;
    
                  if(response.code==='00'){
                    setFormData({
                      contact_type:'IT Solutions',
                      company_name:'',
                      full_name:'',
                      email:'',
                      phone_number:'',
                      message:''
                    });
                    setFormErrors({
                      success: response.description,
                    });
                    const intervalId = setInterval(() => {
                      setFormErrors({
                        success: '',
                      });
                      clearInterval(intervalId);
                    }, 10000);
                  } else {
                    setFormErrors({
                      ...formErrors,
                      common_err: response.description,
                    });
                  }
                }

                if (submitBtnElement) {
                  submitBtnElement.classList.remove("disabled");
                }
              }
          )
          .catch((err) =>{
            console.log('err');  
            if (submitBtnElement) {
              submitBtnElement.classList.remove("disabled");
            }
              }
          );
    
          console.log('Form data submitted:', formData);
        } else {
          console.log('Form contains errors');
          if (submitBtnElement) {
            submitBtnElement.classList.remove("disabled");
          }
        }
    
    
      };

  return (
    <>
    <section className='itsolution-area gradient-background10'>
        <div className="wave21 d-lg-block">
            <img src="assets/imgs/wave-21.png" alt="" />
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <h6 className="seamless-heading abut-txt text-left">We make the process easy</h6>
                </div>
                
                    <div className="tracking-facility text-left">
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/it-icon1.svg" alt="" />
                            <h6>Software Development</h6>
                            <p>Tailored software solutions to enhance your business operations.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/it-icon2.svg" alt="" />
                            <h6>IT Consulting</h6>
                            <p>Expert advice to streamline your IT infrastructure and strategy.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/it-icon3.svg" alt="" />
                            <h6>Cybersecurity</h6>
                            <p>Comprehensive security measures to protect your data and systems.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="tracking-inr-box w-100">
                            <img src="assets/imgs/it-icon4.svg" alt="" />
                            <h6>It Support And Maintenance</h6>
                            <p>Reliable support to keep your IT systems running smoothly.</p>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <div className="wave22 d-lg-block">
            <img src="assets/imgs/wave-22.png" alt="" />
        </div>
    </section>

    <section className="featuresbx itsolution-business">
        <div className="container">
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <h6 className='seamless-heading itbusiness'>Comprehensive <span>business solutions</span></h6>
                    <p>Our services help optimize processes, enhance flexibility, and drive strategic success.</p>
                </div>
            </div>
                
            <div className="featuresbx-inr itsolution-inr">
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv wlblepic'><img src="assets/imgs/it-icon9.svg" alt="" /></div>
                        <h4>Application Development</h4>
                        <p>We build high-quality, scalable software products, offering next-gen consumer-facing and B2B app development services.</p>
                            <div className="it-listing">
                                <ul>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Web App Development</li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Mobility Solution</li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />ERP</li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Legacy Application</li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />SaaS</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv wlblepic'><img src="assets/imgs/it-icon10.svg" alt="" /></div>
                            <h4>Business Intelligence</h4>
                            <p>TCB Pay’s BI services optimize processes and drive success with powerful tools for any environment.</p>
                            <div className="it-listing">
                                <ul>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Data Integration </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Data Analysis and Reporting </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Data Visualization </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Predictive Analytics </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Self-Service</li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Mobile BI</li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Integration with Business Applications</li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Meta Data Management</li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Data Quality </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />AI Modeling/Generative</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv wlblepic'><img src="assets/imgs/it-icon11.svg" alt="" /></div>
                            <h4>IOT</h4>
                            <p>TCB Pay offers custom solutions for real-time data, cloud storage, and predictive analysis. We provide GPS and Bluetooth devices and specialize in mesh networks.</p>
                            <div className="it-listing">
                                <ul>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />IoT PoC & Prototype </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Development </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Industrial IoT (IIoT) Solutions </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />IoT Solutions For OEMs</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                        <div className='imginerdiv wlblepic'><img src="assets/imgs/it-icon12.svg" alt="" /></div>
                            <h4>Cloud Services</h4>
                            <p>Cloud computing offers businesses significant benefits in efficiency, flexibility, and strategic value.</p>
                            <div className="it-listing">
                                <ul>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Scalability </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Innovation </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Cost-Effective </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Improved DevOps</li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Enhanced Compliance & Security </li>
                                <li><img src="assets/imgs/it-icon13.svg" alt="" />Flexibility</li>
                                </ul>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>   
        </div>
    </section>

    {/* <section className='itsolution-contact'>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="blue-dots2" />
        <div className='container'>
            <div className='row justify-content-md-center'>
               <div className='col-md-9 position-relative'>
                <img src="assets/imgs/it-icon5.svg" alt="" className='itsolution-icn1'/>
                <img src="assets/imgs/it-icon6.svg" alt="" className='itsolution-icn2'/>
                <div className='it-contact'>
                  <h6 className='seamless-heading'><span>Contact us</span> for any service needs</h6>
                  <p>If you need any service, reach out to us. We’re here to provide tailored solutions to meet <br />your business requirements.</p>
                  <Link to="" className="get-btn2">Get Started </Link>  
                </div>
                
                <img src="assets/imgs/it-icon7.svg" alt="" className='itsolution-icn3'/>
                <img src="assets/imgs/it-icon8.svg" alt="" className='itsolution-icn4'/>
                </div> 
            </div>
        </div>
    </section> */}

    <section className='its-area gradient-backgroundit'>
    
        <div className="waveit2 d-lg-block">
            <img src="assets/imgs/wave-23.png" alt="" />
        </div>
        <Container>
            <div className='row justify-content-md-center' style={{height:'100vh'}}>
               <div className='col-md-9 position-relative' >
                <img src="assets/imgs/it-icon5.svg" alt="" className='itsolution-icn1'/>
                <img src="assets/imgs/it-icon6.svg" alt="" className='itsolution-icn2'/>

                <img src="assets/imgs/it-icon7.svg" alt="" className='itsolution-icn3'/>
                <img src="assets/imgs/it-icon8.svg" alt="" className='itsolution-icn4'/>
                </div> 
            </div>
        </Container>
        <div className="waveit d-lg-block">
            <img src="assets/imgs/wave-24.png" alt="" />
        </div>
        
    </section>
    

    <section className='new-contactbx' ref={ref}>
        <Container>
            <Row>
              <Col xs lg="6" className='m-auto'>
              <div className='contact-bx'>
                <div className='contact-txt'>
                  <img src='assets/imgs/contact-icn1.svg' alt='' /> Let’s talk
                  </div>
                  <div className='contact-txt1'>To get started, please fill out the form so we can assist you. Briefly describe your IT needs, and a team member will contact you as soon as possible.</div>
                  <form id="contactForm" name="contactForm" className="contactForm" onSubmit={handleSubmit}>
                      <div className="row">
                      <div className="col-md-6">
                          <Form.Group className="mb-3">
                          <Form.Control type="text" name='company_name' maxLength={50} value={formData.company_name} onChange={handleChange} placeholder="Business Name" />
                          {formErrors.company_name && <span style={{ color: 'red' }}>{formErrors.company_name}</span>}
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-3">
                          <Form.Control type="text" name='full_name' maxLength={50} value={formData.full_name} onChange={handleChange} placeholder="Full Name" />
                          {formErrors.full_name && <span style={{ color: 'red' }}>{formErrors.full_name}</span>}
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="mb-3">
                          <Form.Control type="text" name='email' maxLength={100} value={formData.email} onChange={handleChange} placeholder="Email Address" />
                          {formErrors.email && <span style={{ color: 'red' }}>{formErrors.email}</span>}
                          </Form.Group>
                        </div>
                        <div className="col-md-6"> 
                          <Form.Group className="mb-3">
                          <Form.Control type="text" name='phone_number' maxLength={12} value={formData.phone_number} onChange={handleChange} placeholder="Phone Number" />
                          {formErrors.phone_number && <span style={{ color: 'red' }}>{formErrors.phone_number}</span>}
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="mb-3">
                          <Form.Control as="textarea" name='message' value={formData.message} rows={6} onChange={handleChange} placeholder="Your Message" />
                          {formErrors.message && <span style={{ color: 'red' }}>{formErrors.message}</span>}
                          </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3"> 
                        <ReCAPTCHA
                          sitekey={config.gCaptchaSiteKey}
                          onChange={handleCaptchaChange}
                          ref={captchaRef}
                        />
                        {formErrors.captcha && <span style={{ color: 'red' }}>{formErrors.captcha}</span>}
                        </div>
                        
                        <div className="col-md-12">
                          <Form.Group>
                            <Button type="submit" id="submitBtn" className="btn btn-warning w-100">Send Message</Button>
                          </Form.Group>
                        </div>
                        <div className="col-md-12 mt-3"> 
                        {formErrors.common_err && <span style={{ color: 'red' }}>{formErrors.common_err}</span>}
                        {formErrors.success && <span className='frmcd'>{formErrors.success}</span>}
                        </div>
                      </div>
                  </form>
              
                </div>
              </Col>
            </Row>
        </Container>
    </section> 
    <section className='itsolution-contact'>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="blue-dots2" />
    </section>
    
    </>
  );
});

