import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = () => {
  return (
    <>
      <section className='banner-bg bg-transparentcustm'>
        <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />

        <Container>
          <Row>
            <div className='text-left col-md-12'>
              <span><img src='assets/imgs/i-icon.svg' alt='' style={{ width: '17px' }} />PORTAL WIKI</span>
              <h2>A step-by-step guide for TCB Pay Portal</h2>
            </div>
            <div className='text-left col-md-7'>
              <p>Optimize your business processes with this comprehensive TCB Pay Portal guide for transaction management, user account control, and report generation. Ideal for both beginners and advanced users, this guide provides strategies to boost operational efficiency, manage financial transactions, and fully leverage TCB Pay Portal’s tools for business growth and success.</p>
            </div>
          </Row>
        </Container>

      </section>
    </>
  )
}
