import axios from 'axios'
import config from '../Config';
export class FaqService{
    private static URL:string = config.internalApiUrl;

    public static getFaqCategory(){
        let UserURL:string = `${this.URL}/faq_category.php`;
        return axios.post(UserURL)
    }

    public static getFaqList(seo_url:any){
        const postData = {
            category: seo_url,
            is_search: 0
          };
        let UserURL:string = `${this.URL}/faq_list.php` 
        return axios.post(UserURL,postData,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
        })
    }

    public static getSearchFaqList(seo_url:any){
        const postData = {
            category: seo_url,
            is_search: 1
          };
        let UserURL:string = `${this.URL}/faq_list.php` 
        return axios.post(UserURL,postData,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
        })

    }
}