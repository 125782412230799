import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = ({ scrollRef }: { scrollRef: React.RefObject<HTMLDivElement> }) => {

  const scrollToMiddle = () => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };
  return (
    <>
    <section className='banner-bg bg-transparentcustm ourtrm'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <div className='text-left col-md-12'>
            <span><img src={`${config.siteBaseUrl}/assets/imgs/p-icon.svg`} alt='' style={{zIndex:'0'}}/>PARTNERS</span>
            <h2>Explore dynamic partnerships with TCB Pay</h2>
            </div>
            <div className='text-left col-md-6'>
            <p>There are many different ways to partner with TCB Pay, whether you would like to add a payment option to your platform, offer new software, add technology and hardware to our catalog, become an agent or a registered ISO or PayFac. </p>
            <p className='bold-700'>We want to offer the best options to our merchants, so we are always open to discussing new opportunities to grow their businesses.</p>
            <button className="get-btn2" onClick={scrollToMiddle}>Get Started</button>
            <Link className="contact-btn1" to={`${config.siteBaseUrl}contact`}>Contact Us</Link>
            </div>
          </Row>    
        </Container>

    </section>
    </>
  )
}
