import React,{useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route, Link, NavLink,useLocation } from 'react-router-dom';
import StickyBox from "react-sticky-box";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

export const IntrigrationLeftSec = () => {

    const location = useLocation(); 
    const segment_1 = location.pathname.split('/')[1];
    const segment_2 = location.pathname.split('/')[2];
    const segment_3 = location.pathname.split('/')[3];

    let selectedmenuname = '';

    if(segment_3!==undefined){
        switch (segment_3) {
            case 'sale':
            selectedmenuname = 'Sale';
            break;
            case 'ani':
            selectedmenuname = 'ANI';
            break;
            case 'apple-pay':
                selectedmenuname = 'Apple Pay';
            break;
            case 'authorize':
                selectedmenuname = 'Authorize';
            break;
            case 'capture':
                selectedmenuname = 'Capture';
            break;
            case 'void':
                selectedmenuname = 'Void';
            break;
            case 'refund':
                selectedmenuname = 'Refund';
            break;
            case 'report':
                selectedmenuname = 'Report';
            break;
            case 'sandbox':
                selectedmenuname = 'Sandbox';
            break;
            case 'unreferenced-refund':
                selectedmenuname = 'Unreferenced Refund';
            break;
            case 'add-card':
                selectedmenuname = 'Add Card';
            break;
            case 'card-details':
                selectedmenuname = 'Card Details';
            break;
            case 'delete-card':
                selectedmenuname = 'Delete Card';
            break;
            case 'response-codes':
                selectedmenuname = 'Response Codes';
            break;
            case 'add-plan':
                selectedmenuname = 'Add Plan';
            break;
            case 'update-plan':
                selectedmenuname = 'Update Plan';
            break;
            case 'plan-list':
                selectedmenuname = 'Plan List';
            break;
            case 'delete-plan':
                selectedmenuname = 'Delete Plan';
            break;
            case 'add-subscription':
                selectedmenuname = 'Add Subscription';
            break;
            case 'apple-pay-subscription':
                selectedmenuname = 'Apple Pay Subscription';
            break;
            case 'subscription-list':
                selectedmenuname = 'Subscription List';
            break;
            case 'delete-subscription':
                selectedmenuname = 'Delete Subscription';
            break;
            case 'bank-verification':
                selectedmenuname = 'Bank Verification';
            break;
            default:
                selectedmenuname = segment_3;
            break;
        }
    } else {
        switch (segment_2) {
            case 'shopify':
                selectedmenuname = 'Shopify';
            break;
            case 'PrestaShop':
                selectedmenuname = 'PrestaShop';
            break;
            case 'OpenCart':
                selectedmenuname = 'OpenCart';
            break;
            case 'WooCommerce':
                selectedmenuname = 'WooCommerce';
            break;
            case 'magento':
                selectedmenuname = 'Magento';
            break;
            case 'XCart':
                selectedmenuname = 'XCart';
            break;
            case 'osCommerce':
                selectedmenuname = 'osCommerce';
            break;
            case 'ZenCart':
                selectedmenuname = 'Zen Cart';
            break;
            case 'konnektive-crm':
                selectedmenuname = 'Konnektive CRM';
            break;
            case 'sticky':
                selectedmenuname = 'Sticky';
            break;
            default:
                selectedmenuname = segment_2;
            break;
        }        
    }

    useEffect(() => {

        const elementBtn = document.getElementById("menuBtnResp");
        if (elementBtn) {
          elementBtn.classList.add("collapsed");
        }
        const element = document.getElementById("collapse7");
        if (element) {
          element.classList.remove("show");
        }

    }, []);

  return (
    <>
    <StickyBox offsetTop={50} offsetBottom={20} className='stickdynamic'>
    <div className="api-menu">
        <h3 className="d-none d-sm-block">APIs</h3>
        <div className="accordion menu-collapse apitoggle" id="api_accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header sub-menu-heading" id="headingOne">
                <button className={segment_2=='credit-card-API' ? `accordion-button` : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                 Credit Card
                </button>
                </h2>
                <div id="collapseOne" className={segment_2=='credit-card-API' ? `accordion-collapse collapse show` : 'accordion-collapse collapse'} aria-labelledby="headingOne" data-bs-parent="#api_accordionExample">
                <div className="accordion-body">
                    <ul>
                        <li>
                            <NavLink to="/integrations/credit-card-API/sale">Sale</NavLink>
                        </li>
                        <li>
                            <NavLink to="/integrations/credit-card-API/ani-API">ANI</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/credit-card-API/apple-pay'>Apple Pay</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/credit-card-API/authorize'>Authorize</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/credit-card-API/capture'>Capture</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/credit-card-API/void'>Void</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/credit-card-API/refund'>Refund</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/credit-card-API/report'>Report</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/credit-card-API/sandbox'>Sandbox</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/credit-card-API/unreferenced-refund'>Unreferenced Refund</NavLink>
                        </li>
                    </ul>
                </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header sub-menu-heading" id="headingTwo">
                <button className={segment_2=='vault-API' ? `accordion-button` : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Vault
                </button>
                </h2>
                <div id="collapseTwo" className={segment_2=='vault-API' ? `accordion-collapse collapse show` : 'accordion-collapse collapse'} aria-labelledby="headingTwo" data-bs-parent="#api_accordionExample">
                <div className="accordion-body">
                    <ul>
                        <li>
                            <NavLink to='/integrations/vault-API/add-card'>Add Card</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/vault-API/card-details'>Card Details</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/vault-API/delete-card'>Delete Card</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/vault-API/sale'>Sale</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/vault-API/authorize'>Authorize</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/vault-API/response-codes'>Response Codes</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/vault-API/sandbox'>Sandbox</NavLink>
                        </li>
                    </ul>                                
                </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header sub-menu-heading" id="headingThree">
                <button className={segment_2=='subscription-API' ? `accordion-button` : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Subscriptions
                </button>
                </h2>
                <div id="collapseThree" className={segment_2=='subscription-API' ? `accordion-collapse collapse show` : 'accordion-collapse collapse'} aria-labelledby="headingThree" data-bs-parent="#api_accordionExample">
                <div className="accordion-body">
                    <ul>
                        <li>
                            <NavLink to='/integrations/subscription-API/add-plan'>Add Plan</NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/subscription-API/update-plan'>Update Plan </NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/subscription-API/plan-list'>Plan List </NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/subscription-API/delete-plan'>Delete Plan </NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/subscription-API/add-subscription'>Add Subscription </NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/subscription-API/apple-pay-subscription'>Apple Pay Subscription </NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/subscription-API/subscription-list'>Subscription List </NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/subscription-API/delete-subscription'>Delete Subscription </NavLink>
                        </li>
                        <li>
                            <NavLink to='/integrations/subscription-API/response-codes'>Response Codes </NavLink>
                        </li>
                        
                    </ul>                                
                </div>
                </div>
            </div>

            <div className="accordion-item">
                <h2 className="accordion-header sub-menu-heading" id="headingfour">
                    <button className={segment_2=='bank-verification-API' ? `accordion-button` : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                    Bank Verification
                    </button>
                </h2>
                <div id="collapsefour" className={segment_2=='bank-verification-API' ? `accordion-collapse collapse show` : 'accordion-collapse collapse'} aria-labelledby="headingfour" data-bs-parent="#api_accordionExample">
                    <div className="accordion-body">
                        <ul>
                            <li>
                                <NavLink to='/integrations/bank-verification-API/bank-verification'>Bank Verification</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/bank-verification-API/response-codes'>Response Codes</NavLink>
                            </li>
                        </ul>                                
                    </div>
                </div>
            </div>

            {/* <div className="accordion-item">
                <h2 className="accordion-header sub-menu-heading" id="headingach">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseach" aria-expanded="false" aria-controls="collapseach">
                    ACH
                    </button>
                </h2>
            </div> */}

        </div>



        <div className="accordion menu-collapse apitoggle-mb" id="api-accordion">
        <div className="accordion-item">
            <h2 className="accordion-header" id="heading7">
            <button className="accordion-button" id="menuBtnResp" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
            {selectedmenuname}
            </button>
            </h2>
            <div id="collapse7" className="accordion-collapse collapse show" aria-labelledby="heading7" data-bs-parent="#api-accordion">
            <div className="accordion-body">

                <div className="accordion-item">
                    <h2 className="accordion-header sub-menu-heading" id="heading1">
                    <button className={segment_2=='credit-card-API' ? `accordion-button` : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    Credit Card
                    </button>
                    </h2>
                    <div id="collapse1" className={segment_2=='credit-card-API' ? `accordion-collapse collapse show` : 'accordion-collapse collapse'} aria-labelledby="heading1" data-bs-parent="#api-accordion1">
                    <div className="accordion-body">
                        <ul>
                            <li>
                                <NavLink to="/integrations/credit-card-API/sale">Sale</NavLink>
                            </li>
                            <li>
                                <NavLink to="/integrations/credit-card-API/ani-API">ANI</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/credit-card-API/apple-pay'>Apple Pay</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/credit-card-API/authorize'>Authorize</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/credit-card-API/capture'>Capture</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/credit-card-API/void'>Void</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/credit-card-API/refund'>Refund</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/credit-card-API/report'>Report</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/credit-card-API/sandbox'>Sandbox</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/credit-card-API/unreferenced-refund'>Unreferenced Refund</NavLink>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header sub-menu-heading" id="heading2">
                    <button className={segment_2=='vault-API' ? `accordion-button` : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                        Vault
                    </button>
                    </h2>
                    <div id="collapse2" className={segment_2=='vault-API' ? `accordion-collapse collapse show` : 'accordion-collapse collapse'} aria-labelledby="heading2" data-bs-parent="#api-accordion1">
                    <div className="accordion-body">
                        <ul>
                            <li>
                                <NavLink to='/integrations/vault-API/add-card'>Add Card</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/vault-API/card-details'>Card Details</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/vault-API/delete-card'>Delete Card</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/vault-API/sale'>Sale</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/vault-API/authorize'>Authorize</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/vault-API/response-codes'>Response Codes</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/vault-API/sandbox'>Sandbox</NavLink>
                            </li>
                        </ul>                                
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header sub-menu-heading" id="heading3">
                    <button className={segment_2=='subscription-API' ? `accordion-button` : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                        Subscriptions
                    </button>
                    </h2>
                    <div id="collapse3" className={segment_2=='subscription-API' ? `accordion-collapse collapse show` : 'accordion-collapse collapse'} aria-labelledby="heading3" data-bs-parent="#api-accordion1">
                    <div className="accordion-body">
                        <ul>
                            <li>
                                <NavLink to='/integrations/subscription-API/add-plan'>Add Plan</NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/subscription-API/update-plan'>Update Plan </NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/subscription-API/plan-list'>Plan List </NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/subscription-API/delete-plan'>Delete Plan </NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/subscription-API/add-subscription'>Add Subscription </NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/subscription-API/apple-pay-subscription'>Apple Pay Subscription </NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/subscription-API/subscription-list'>Subscription List </NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/subscription-API/delete-subscription'>Delete Subscription </NavLink>
                            </li>
                            <li>
                                <NavLink to='/integrations/subscription-API/response-codes'>Response Codes </NavLink>
                            </li>
                            
                        </ul>                                
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header sub-menu-heading" id="heading4">
                        <button className={segment_2=='bank-verification-API' ? `accordion-button` : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                        Bank Verification
                        </button>
                    </h2>
                    <div id="collapse4" className={segment_2=='bank-verification-API' ? `accordion-collapse collapse show` : 'accordion-collapse collapse'} aria-labelledby="heading4" data-bs-parent="#api-accordion1">
                        <div className="accordion-body">
                            <ul>
                                <li>
                                    <NavLink to='/integrations/bank-verification-API/bank-verification'>Bank Verification</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/integrations/bank-verification-API/response-codes'>Response Codes</NavLink>
                                </li>
                            </ul>                                
                        </div>
                    </div>
                </div>

                <h3>Integrations</h3>
        <div className='leftmenuin'>
            <ul>
                <li><NavLink to='/integrations/shopify'>Shopify</NavLink></li>
                <li><NavLink to='/integrations/PrestaShop'>PrestaShop</NavLink></li>
                <li><NavLink to='/integrations/OpenCart'>OpenCart</NavLink></li>
                <li><NavLink to='/integrations/WooCommerce'>WooCommerce</NavLink></li>
                <li><NavLink to='/integrations/magento'>Magento</NavLink></li>
                <li><NavLink to='/integrations/XCart'>XCart</NavLink></li>
                <li><NavLink to='/integrations/osCommerce'>osCommerce</NavLink></li>
                <li><NavLink to='/integrations/ZenCart'>Zen Cart</NavLink></li>
                <li><NavLink to='/integrations/konnektive-crm'>Konnektive CRM</NavLink></li>
                <li><NavLink to='/integrations/sticky'>Sticky</NavLink></li>
            </ul>
        </div>

            </div>
            </div>
        </div>
        </div>
        
        <hr className='hrborder'/>
        <h3 className='d-none d-sm-block'>Integrations</h3>
        <div className='leftmenuin d-none d-sm-block'>
            <ul>
                <li><NavLink to='/integrations/shopify'>Shopify</NavLink></li>
                <li><NavLink to='/integrations/PrestaShop'>PrestaShop</NavLink></li>
                <li><NavLink to='/integrations/OpenCart'>OpenCart</NavLink></li>
                <li><NavLink to='/integrations/WooCommerce'>WooCommerce</NavLink></li>
                <li><NavLink to='/integrations/magento'>Magento</NavLink></li>
                <li><NavLink to='/integrations/XCart'>XCart</NavLink></li>
                <li><NavLink to='/integrations/osCommerce'>osCommerce</NavLink></li>
                <li><NavLink to='/integrations/ZenCart'>Zen Cart</NavLink></li>
                <li><NavLink to='/integrations/konnektive-crm'>Konnektive CRM</NavLink></li>
                <li><NavLink to='/integrations/sticky'>Sticky</NavLink></li>
            </ul>
        </div>




    </div>
    </StickyBox>
    </>
  )
}
