import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';


export const IntrigrationSec = () => {

  return (
    <>
    <section className="credit-api-main">
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <IntrigrationLeftSec />
                </div>
                <div className="col-lg-9">
                    <div className="creadit-api-upper-wrapper">
                          <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                        <h1>Vault API</h1>

                        
                    </div>

                    <div className="creadit-api-lower-wrapper">
                        <div className="api-menu-item-title">
                            <h2>Response Codes</h2>
                        </div>
                    </div>

                    <div className="api-table">
                                <div className="table-responsive">
                                  <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <th>Code</th>
                                        <th>Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>01</td>
                                        <td>Username is missing or invalid</td>
                                      </tr>
                                      <tr>
                                        <td>02</td>
                                        <td>Credit Card is missing or invalid</td>
                                      </tr>
                                      <tr>
                                        <td>03</td>
                                        <td>Expiry Month missing or invalid</td>
                                      </tr>
                                      <tr>
                                        <td>04</td>
                                        <td>Expiry Year missing or invalid</td>
                                      </tr>
                                      <tr>
                                        <td>05</td>
                                        <td>Expiry Date is invalid</td>
                                      </tr>
                                      <tr>
                                        <td>06</td>
                                        <td>CVV missing or invalid</td>
                                      </tr>
                                      <tr>
                                        <td>07</td>
                                        <td>Card holder's Name missing or Credit Card Number missing</td>
                                      </tr>
                                      <tr>
                                        <td>08</td>
                                        <td>Token Missing</td>
                                      </tr>
                                      <tr>
                                        <td>09</td>
                                        <td>API Key is missing or invalid</td>
                                      </tr>
                                      <tr>
                                        <td>21</td>
                                        <td>Message contains words that have been identified as profanity and this message has been blocked due to company policy. Please try again.</td>
                                      </tr>
                                      <tr>
                                        <td>23</td>
                                        <td>Token does not exist</td>
                                      </tr>
                                      <tr>
                                          <td>26</td>
                                          <td>Potential duplicate transaction</td>
                                      </tr>
                                      <tr>
                                        <td>UN</td>
                                        <td>Unknown error, please contact an administrator</td>
                                      </tr>
                                      <tr>
                                        <td>F</td>
                                        <td>Your transaction is declined</td>
                                      </tr>
                                      <tr>
                                        <td>00</td>
                                        <td>Transaction entry is successful</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                    </div>

                  
                </div>
            </div>
        </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />

        <ScrollToTop smooth />
    </section>
    </>
  )
}



