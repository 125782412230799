import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const IntrigrationSec = () => {

  const exampleCodeText_1 = `<script type="text/javascript" src="https://[server-name]/services/proxynization_api.js"></script>`;
  const exampleCodeText_2 = `<script type="text/javascript" >
    function tcbpayClientCallback(responseCode, responseMessage, proxyNumber){
      if(responseCode == "A01"){
      document.getElementById('creditcard_number').value = proxyNumber;
      document.getElementById("formID").submit();
      } else{
      alert(responseMessage);
      return false;
      }
    }
  </script>
  `;
  const exampleCodeText_3 = `<input type="button" value="submit" onClick="TCBPayProxynizationAPI.process('#creditcard_number','tcbpayClientCallback');" />`;
  const exampleCodeText_4 = `  <form name="" id="formID" action="${config.gatewayApisUrlProd}/apis/sale.php" method="post">
  <input type="text" name="username" value="tcbpayusername"/>
  <input type="text" name="api_key" value="tcbpayapikey"/>
  <input type="text" name="creditcard_number" id="creditcard_number" value="4111111111111111"/>
  <input type="text" name="expire_month" value="06"/>
  <input type="text" name="expire_year" value="2025"/>
  <input type="text" name="cvv" value="123"/>
  <input type="text" name="amount" value="14.5"/>
  <input type="text" name="currency" value="USD"/>
  <input type="text" name="company_name" value="TCB Pay"/>
  <input type="text" name="first_name" value="Roberto"/>
  <input type="text" name="last_name" value="Diaz"/>
  <input type="text" name="address" value="4446 Green Avenue, Suite 56"/>
  <input type="text" name="city" value="Los Angeles"/>
  <input type="text" name="state" value="CA"/>
  <input type="text" name="country_code" value="US"/>
  <input type="text" name="zip_code" value="11111"/>
  <input type="text" name="phone_number" value="323-323-3223"/>
  <input type="text" name="client_ip" value="127.0.0.1"/>
  <input type="text" name="email" value="abcdef@mail.com"/>
  <input type="checkbox" name="email_receipt"  value="Y"/>
  <br/>
  <input type="button" value="submit" onClick="TCBPayProxynizationAPI.process('#creditcard_number','tcbpayClientCallback');" />
</form>

<script type="text/javascript" >
  function tcbpayClientCallback(responseCode, responseMessage, proxyNumber){
    if(responseCode == "A01"){
    document.getElementById('creditcard_number').value = proxyNumber;
    document.getElementById("formID").submit();
    } else{
    alert(responseMessage);
    return false;
    }
  }
</script>`;
  const exampleCodeText_5 = `{"response":{"code":"00","description":" Transaction Entry Success","transaction_id":102," total_amount":15.7," convenience_fee":1.2}}`;
  const exampleCodeText_6 = `{"response":{"code":"00","description":"Transaction pending cardholder authentication","transaction_id":102,"redirect_url":"https://[server-name]/challenge_request.php?uid=xxxxxxxxx"}}`;

  const onCopy = React.useCallback((id:number) => {
    const element = document.getElementById("myTooltip"+id);
    if (element) {
      element.classList.add("show");
      const intervalId = setInterval(() => {
        element.classList.remove("show");
        clearInterval(intervalId);
      }, 2000);
    }
  }, []);

  return (
    <>
    <section className="credit-api-main">
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <IntrigrationLeftSec />
                </div>
                <div className="col-lg-9">
                    <div className="creadit-api-upper-wrapper">
                          <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                        <h1>Credit Card API</h1>

                        <ul className="card-url">
                            <li>
                                <div className="card-url-list">
                                     <p>Sandbox endpoint URL:</p>
                                     <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                                </div>
                              
                            </li>
                            
                            <li>
                                <div className="card-url-list">
                                    <p>Example:</p>
                                    <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                                </div>
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Production endpoint URL:</p>
                                    <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="creadit-api-lower-wrapper">
                        <div className="api-menu-item-title">
                            <h2>Sale</h2>
                        </div>
                        <div className="api-menu-item-wrapper">
                            <h3>Sale API</h3>
                            <hr />
                            <p>This API is responsible for creating new payment transactions, and this API is compatible with both encrypted and plain credit card methods of transactions.</p>
                            <ul className="card-url api-menu-url pb-40">
                                <li>
                                    <div className="card-url-list">
                                         <p>URL to call</p>
                                         <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                                    </div>
                                  
                                </li>
                                <li>
                                    <div className="card-url-list">
                                        <p>Method of form submission</p>
                                        <span><Link to=''>POST</Link></span>
                                   </div>
                                </li>
                                <br />
                                <li>
                                    <div className="card-url-list">
                                         <p>Proxynization APIs</p>
                                    </div>
                                  
                                </li>
                                <li>
                                    <div className="card-url-list">
                                         <p>Sandbox </p>
                                         <span><Link to=''>{config.gatewayApisUrlSand}/services/proxynization_api.js</Link></span>
                                    </div>
                                  
                                </li>
                                <li>
                                    <div className="card-url-list">
                                        <p>Production</p>
                                        <span><Link to=''>{config.gatewayApisUrlProd}/services/proxynization_api.js</Link></span>
                                   </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>

                    <div className="example-box-wrapper mt-100 mb-50">
                        <div className="d-flex align-items-start">
                            <b>1 </b>
                            <p>Include proxynization_api.js script file. Please copy the following lines and paste them to your HTML page to enable the proxynization functionality within your application.
                            <span>Example</span>
                            </p>
                        </div>
                        
                        
                        <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip1">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(1)} text={exampleCodeText_1}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                               
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_1">
                                <pre>{exampleCodeText_1}</pre>
                            </div>
    
                        </div>

                    </div>

                    <div className="example-box-wrapper mb-50">
                        <div className="d-flex align-items-start">
                            <b className="">2 </b>
                            <p>Implement the callback function. The callback function will be invoked once the proxynization response is returned from our system. We make a function call which invokes the pre-defined callback function, and the proxynization response is used as an argument.
                            <span>Example</span>
                            </p>
                        </div>
                        
                       
                        <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip2">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(2)} text={exampleCodeText_2}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            </div>
                            <div className="ex-text-box">
                                <pre>{exampleCodeText_2}</pre>
                            </div>
                        </div>
                    </div>

                    <div className="example-box-wrapper mb-50">
                        <div className="d-flex align-items-start">
                            <b className="me-3">3</b>
                            <p> Design your payment form and insert a proxynization call within the form’s submit action.
                            <ul className='api-innerlist'>
                            <li>
                                <div className="d-flex align-items-start mb-20">
                                    <b className="me-3">3.1</b><p> The first parameter can either be the value of the credit card number or the ID of the credit card number field. If the value of the parameter starts with a hashtag (#) symbol, the first parameter is assumed to be the ID of the credit card number field. If a hashtag is absent, the first parameter is assumed to be a credit card number.</p>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex align-items-start">
                                    <b className="me-3">3.2</b><p> The second parameter is the name of the callback function, which will be called when the proxynization call is completed.</p>
                                </div>
                            </li>
                            </ul>
                            <span>Example</span>
                            </p>

                        </div>                        
                        
                        <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip3">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(3)} text={exampleCodeText_3}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            </div>
                            <div className="ex-text-box">
                              <pre>{exampleCodeText_3}</pre>
                            </div>
                        </div>
                    </div>

                    <div className="api-table">
                        <h5>This table describes the request parameters for Authorize API.</h5>

                        <div className="table-responsive">
                            <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th>Variable Name</th>
                                  <th>Description</th>
                                  <th>Required</th>
                                  <th>Expected Values</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>username</td>
                                  <td>TCB Pay Gateway Username</td>
                                  <td>Y</td>
                                  <td>TCB Pay Gateway Username</td>
                                </tr>
                                <tr>
                                  <td>api_key</td>
                                  <td>TCB Pay Gateway API Key</td>
                                  <td>Y</td>
                                  <td>TCB Pay Gateway API Key</td>
                                </tr>
                                <tr>
                                  <td>card_token</td>
                                  <td>Get a token during Add Card Vault API. If you use a card token, the credit card number, expiry month, and expiry year are not required.</td>
                                  <td>C</td>
                                  <td>XXXXXXXXXXXXXXXXXXXXXXXXX</td>
                                </tr>
                                <tr>
                                  <td>creditcard_number</td>
                                  <td>Credit Card Number that is used to complete the sale. If you use a card token number, the credit card number is not required.</td>
                                  <td>C</td>
                                  <td>4111111111111111</td>
                                </tr>
                                <tr>
                                  <td>expire_month</td>
                                  <td>Expiry Month of the Credit Card. This is not required if you use a card token number.</td>
                                  <td>C</td>
                                  <td>06</td>
                                </tr>
                                <tr>
                                  <td>expire_year</td>
                                  <td>Expiry Year of the Credit Card. This is not required if you use a card token number.</td>
                                  <td>C</td>
                                  <td>2025</td>
                                </tr>
                                <tr>
                                  <td>cvv</td>
                                  <td>CVV of the Credit Card.  <br />    
                      Change CVV requirement as optional/mandatory from merchant's account settings for all transactions.
                      </td>
                                  <td>C</td>
                                  <td>123</td>
                                </tr>
                                <tr>
                                  <td>use_secret_key</td>
                                  <td>Set the secret key in the account setting to encrypt creditcard_number without using proxynization_api.js. Make sure to encrypt credit card numbers with AES 128-bit ECB cipher code.</td>
                                  <td>N</td>
                                  <td>Y or N (default value N) <br />
                                      Y- encrypt creditcard_number using AES 128 bit ECB cipher mode encryption

                                  </td>
                                </tr>
                                <tr>
                                  <td>amount</td>
                                  <td>Amount to be charged</td>
                                  <td>Y</td>
                                  <td>133.24</td>
                                </tr>
                                <tr>
                                  <td>currency</td>
                                  <td>Currency of Transaction (allowed currencies will be determined during the time of merchant boarding).</td>
                                  <td>Y</td>
                                  <td>USD,EUR,GBP,CAD,AUD,NZD,AED,<br />DKK,MXN,SEK,CHF</td>
                                </tr>
                                <tr>
                                  <td>company_name</td>
                                  <td>Company Name of the cardholder</td>
                                  <td>N</td>
                                  <td>-</td>
                                </tr>
                                <tr>
                                  <td>first_name</td>
                                  <td>First name of the cardholder</td>
                                  <td>Y</td>
                                  <td>Roberto</td>
                                </tr>
                                <tr>
                                  <td>last_name</td>
                                  <td>Last name of the cardholder</td>
                                  <td>Y</td>
                                  <td>Diaz</td>
                                </tr>
                                <tr>
                                  <td>address</td>
                                  <td>Address of the cardholder</td>
                                  <td>N</td>
                                  <td>4446 Green Avenue, Suite 56</td>
                                </tr>
                                <tr>
                                  <td>city</td>
                                  <td>City of the cardholder</td>
                                  <td>N</td>
                                  <td>Los Angeles</td>
                                </tr>
                                <tr>
                                  <td>state</td>
                                  <td>State of the cardholder</td>
                                  <td>N</td>
                                  <td>CA</td>
                                </tr>
                                <tr>
                                  <td>country_code</td>
                                  <td>Country of the cardholder(ISO alpha-2)</td>
                                  <td>Y</td>
                                  <td>US, CN</td>
                                </tr>
                                <tr>
                                  <td>zip_code</td>
                                  <td>Zip code of the cardholder</td>
                                  <td>Y</td>
                                  <td>94085</td>
                                </tr>
                                <tr>
                                  <td>phone_number</td>
                                  <td>Phone Number of the cardholder</td>
                                  <td>N</td>
                                  <td>408-345-2323</td>
                                </tr>
                                

                                <tr>
                                  <td>ship_address_as_bill_address</td>
                                  <td>Billing address copy Shipping address</td>
                                  <td>Y</td>
                                  <td>Y or N (default value N) Y- Billing address copy to Shipping address N- Not copy</td>
                                </tr>
                                <tr>
                                  <td>ship_company_name</td>
                                  <td>Shipping Company Name </td>
                                  <td>N</td>
                                  <td>-</td>
                                </tr>
                                <tr>
                                  <td>ship_first_name</td>
                                  <td>Shipping First name </td>
                                  <td>Y</td>
                                  <td>Roberto</td>
                                </tr>
                                <tr>
                                  <td>ship_last_name</td>
                                  <td>Shipping Last name </td>
                                  <td>Y</td>
                                  <td>Diaz</td>
                                </tr>
                                <tr>
                                  <td>ship_address</td>
                                  <td>Shipping Address </td>
                                  <td>N</td>
                                  <td>4446 Green Avenue, Suite 56</td>
                                </tr>
                                <tr>
                                  <td>ship_city</td>
                                  <td>Shipping City </td>
                                  <td>N</td>
                                  <td>Los Angeles</td>
                                </tr>
                                <tr>
                                  <td>ship_state</td>
                                  <td>Shipping State </td>
                                  <td>N</td>
                                  <td>CA</td>
                                </tr>
                                <tr>
                                  <td>ship_country_code</td>
                                  <td>Shipping Country (ISO alpha-2)</td>
                                  <td>Y</td>
                                  <td>US, CN</td>
                                </tr>
                                <tr>
                                  <td>ship_zip_code</td>
                                  <td>Shipping Zip code </td>
                                  <td>Y</td>
                                  <td>94085</td>
                                </tr>
                                <tr>
                                  <td>ship_phone_number</td>
                                  <td>Shipping Phone Number </td>
                                  <td>N</td>
                                  <td>408-345-2323</td>
                                </tr>

                                
                                <tr>
                                  <td>email</td>
                                  <td>Email ID of the cardholder</td>
                                  <td>Y</td>
                                  <td>test@test.com</td>
                                </tr>
                                <tr>
                                  <td>email_receipt</td>
                                  <td>Sends transaction details to specified cardholder email </td>
                                  <td>N</td>
                                  <td>Y or N (default value N) 
                                    Y- Send email 
                                    N- Not send
                                  </td>
                                </tr>
                                <tr>
                                  <td>emailReceiptDBAEmail</td>
                                  <td>Email Receipt to Merchant DBA Contact Email  </td>
                                  <td>N</td>
                                  <td>Y or N (default value N) 
                                    Y- Send email 
                                    N- Not send
                                  </td>
                                </tr>
                                <tr>
                                  <td>memo</td>
                                  <td>Memo of the transaction </td>
                                  <td>N</td>
                                  <td>
                                  </td>
                                </tr>
                                <tr>
                                  <td>client_ip</td>
                                  <td>Client IP address</td>
                                  <td>N</td>
                                  <td>127.0.0.1</td>
                                </tr>
                                <tr>
                                  <td>custom_field_1</td>
                                  <td></td>
                                  <td>N</td>
                                  <td>additional parameter 1</td>
                                </tr>
                                <tr>
                                  <td>custom_filed_2</td>
                                  <td></td>
                                  <td>N</td>
                                  <td>additional parameter 2</td>
                                </tr>
                                <tr>
                                  <td>source</td>
                                  <td></td>
                                  <td>C</td>
                                  <td>source=SHOPIFY if using Shopify otherwise it is not required</td>
                                </tr>
                                <tr>
                                  <td>3ds_redirect_url</td>
                                  <td>Redirect to this URL after 3D-Secure authentication<br />
                                  (To process transactions with 3DS, contact TCB Pay to enable 3DS and set 'Denied Payment Transaction' or 'Continue Payment Transaction' for non-3DS transactions).
                                  </td>
                                  <td>C</td>
                                  <td>if using 3D-Secure then it is required otherwise it is not required.</td>
                                </tr>
                                <tr>
                                  <td>reference_id</td>
                                  <td>Transaction reference id</td>
                                  <td>N</td>
                                  <td>Reference ID should be unique</td>
                                </tr>
                              </tbody>
                            </table>
                        </div>

                        <div className="example-box-wrapper">
                            <div className="d-flex align-items-start">
                                <b></b>
                               <p><span>Example</span></p>
                            </div>
                            <div className="example-box">
                                <div className="example-box-top">
                                <div className="tooltip1" id="myTooltip4">
                                  <span className="tooltiptext">Copied</span>
                                </div>
                                <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                </div>
                                <div className="ex-text-box bighgt">
                                    <pre>{exampleCodeText_4}</pre>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                        <div className="api-menu-item-wrapper mt-60">
                            <h3>Response</h3>
                            <hr />
                            <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, three elements are returned, code, description, and transaction_id. The response code for a successful API call is always 00.</p>
                            <div className="example-box-wrapper mb-20">
                                <div className="d-flex align-items-start">
                                    <b></b>
                                    <span>Sample Response</span>
                                </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                    <div className="tooltip1" id="myTooltip5">
                                      <span className="tooltiptext">Copied</span>
                                    </div>
                                    <CopyToClipboard onCopy={() => onCopy(5)} text={exampleCodeText_5}>
                                      <FontAwesomeIcon icon={faCopy} />
                                    </CopyToClipboard>
                                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                    </div>
                                    <div className="ex-text-box">
                                        <pre>{exampleCodeText_5}</pre>
                                    </div>
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                            <div className="d-flex align-items-start">
                                    <b></b>
                                    <span className='mt-0'>Sample Response for 3D-Secure</span>
                                </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                    <div className="tooltip1" id="myTooltip6">
                                      <span className="tooltiptext">Copied</span>
                                    </div>
                                    <CopyToClipboard onCopy={() => onCopy(6)} text={exampleCodeText_6}>
                                      <FontAwesomeIcon icon={faCopy} />
                                    </CopyToClipboard>
                                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                    </div>
                                    <div className="ex-text-box">
                                        <pre>{exampleCodeText_6}</pre>
                                    </div>
                                </div>
                            </div>
                            <p className="mb-20">Subscriber needs to redirect to redirect_url within 30 seconds.</p>
                            <p>After the 3DS challenge, the API response is redirected to 3ds_redirect_url, parameters are transaction_id, code, and description.</p>
                        </div>
                 
                     


                </div>
            </div>
        </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />

        <ScrollToTop smooth />
    </section>
    </>
  )
}



