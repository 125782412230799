import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const IntegrationCreditSandboxSec = () => {

    const onCopy = React.useCallback((id:number) => {
        const element = document.getElementById("myTooltip"+id);
        if (element) {
          element.classList.add("show");
          const intervalId = setInterval(() => {
            element.classList.remove("show");
            clearInterval(intervalId);
          }, 2000);
        }
      }, []);

  return (
    <>
    <section className="credit-api-main">
    <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <IntrigrationLeftSec />
              </div>
              <div className="col-lg-9">
                  <div className="creadit-api-upper-wrapper">
                        <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                      <h1>Credit Card API</h1>
                      <ul className="card-url">
                          <li>
                              <div className="card-url-list">
                                   <p>Sandbox endpoint URL:</p>
                                   <span><Link to=''>{`${config.gatewayApisUrlSand}/apis/{method}`}</Link></span>
                              </div>
                            
                          </li>
                          
                          <li>
                              <div className="card-url-list">
                                  <p>Example:</p>
                                  <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                              </div>
                          </li>
                          <li>
                              <div className="card-url-list">
                                  <p>Production endpoint URL:</p>
                                  <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                              </div>
                          </li>
                      </ul>
                  </div>

                  <div className="creadit-api-lower-wrapper">
                      <div className="api-menu-item-title">
                          <h2>Sandbox</h2>
                      </div>
                      <div className="api-menu-item-wrapper">
                          <h3>Sandbox</h3>
                          <hr />

                          <ul className="card-url api-menu-url">
                              <li>
                                  <div className="card-url-list">
                                       <p>Sandbox endpoint URL</p>
                                       <span><Link to=''>{`${config.gatewayApisUrlSand}/apis/{method}`}</Link></span>
                                  </div>
                                
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Example:</p>
                                      <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                                 </div>
                              </li>                              
                          </ul>
                      </div>
                  </div>


                  <div className="api-table pt-100 mb-60">
                      <h5>Test Credit Card Details:</h5>

                        <div className="table-responsive">
                            <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                <th style={{ width: '420px' }}>Card Number</th>
                                <th style={{ width: '140px' }}>Type of use</th>
                                <th style={{ width: '140px' }}>Expected Values</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                            <td>4005510000000013</td>
                            <td>Regular</td>
                            <td>123</td>
                            </tr>
                            <tr>
                            <td>4111111111111111</td>
                            <td>Regular</td>
                            <td>111</td>
                            </tr>
                            <tr>
                            <td>4012001037141112</td>
                            <td>Regular</td>
                            <td>083</td>
                            </tr>
                            <tr>
                            <td>5111111111111111</td>
                            <td>Regular</td>
                            <td>123</td>
                            </tr>
                            <tr>
                            <td>4018810000190011 (success OTP: 0101 , fail OTP: 3333)</td>
                            <td>3DS</td>
                            <td>123</td>
                            </tr>
                            <tr>
                            <td>5204730000001011 (success OTP: 4445 , fail OTP: 9999)</td>
                            <td>3DS</td>
                            <td>123</td>
                            </tr>
                            </tbody>
                            </table>
                        </div>

                  </div>
                  


              </div>
          </div>
      </div>
      <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
      <ScrollToTop smooth />
    </section>
    </>
  )
}
