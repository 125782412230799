import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link,NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const IntegrationCreditApplePay = () => {

  const exampleCodeText_1 = `<script type="text/javascript" src="https://[server-name]/services/applePay.js"></script>`;
  const exampleCodeText_2 = `/* CSS */
#applePay {  
    width: 150px;  
    height: 50px;  
    display: none;   
    border-radius: 5px;    
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-image: -webkit-named-image(apple-pay-logo-white); 
    background-position: 50% 50%;
    background-color: black;
    background-size: 60%; 
    background-repeat: no-repeat;  
}

/* HTML */
<button type="button" id="applePay" onclick="callPayment()"></button>`;
  const exampleCodeText_3 = `<script type="text/javascript" >
  function callPayment()
  {
      var amount=document.getElementById("amount").value;
      var currency=document.getElementById("currency").value;
      var app_key="b0cf87c7e314af8638ce34732f1f7d24";
      var business_name="Test shop";

      TCBPayApplePay.paymentRequest(app_key,business_name,amount,currency);
  }

  TCBPayApplePay.paymentResponse=function(responseStatus,responseText){
      if(responseStatus=="S")
      {
          document.getElementById('signature_token').value = responseText;
          document.getElementById("formID").submit();
      }else{
          alert(responseText);
      }
  }

</script>`;
  const exampleCodeText_4 = `<form name="" id="formID" action="${config.gatewayApisUrlProd}/apis/apple_sale.php" method="post">
  <input type="text" name="username" value="tcbpayusername"/>
  <input type="text" name="api_key" value="tcbpayapikey"/>
  <input type="text" name="signature_token" id="signature_token" value="{"identifyKey":"test","data":"test","ephemeralPublicKey":"test","publicKeyHash":"test","transactionId":"test","signature":"test","version":"EC_v1","displayName":"MasterCard 1111","network":"MasterCard","type":"credit"}"/>
  <input type="text" name="company_name" value="TCB Pay"/>
  <input type="text" name="first_name" value="Roberto"/>
  <input type="text" name="last_name" value="Diaz"/>
  <input type="text" name="address" value="4446 Green Avenue, Suite 56"/>
  <input type="text" name="city" value="Los Angeles"/>
  <input type="text" name="state" value="CA"/>
  <input type="text" name="country_code" value="US"/>
  <input type="text" name="zip_code" value="11111"/>
  <input type="text" name="phone_number" value="323-323-3223"/>
  <input type="text" name="client_ip" value="127.0.0.1"/>
  <input type="text" name="email" value="abcdef@mail.com"/>
  <input type="checkbox" name="email_receipt"  value="Y"/>
  <br/>
  <button type="button" id="applePay" onclick="callPayment()"></button> 
</form>
<script type="text/javascript" >
  function callPayment()
  {
    var amount=document.getElementById("amount").value;
    var currency=document.getElementById("currency").value;
    var app_key="b0cf87c7e314af8638ce34732f1f7d24";
    var business_name="Test shop";

    TCBPayApplePay.paymentRequest(app_key,business_name,amount,currency);
  }

  TCBPayApplePay.paymentResponse=function(responseStatus,responseText){
    if(responseStatus=="S")
    {
      document.getElementById('signature_token').value = responseText;
      document.getElementById("formID").submit();
    }else{
      alert(responseText);
    }
  }
</script>`;
  const exampleCodeText_5 = `{"response":{"code":"00","description":" Transaction Entry Success","transaction_id":102," total_amount":15.7}}`;

  const onCopy = React.useCallback((id:number) => {
    const element = document.getElementById("myTooltip"+id);
    if (element) {
      element.classList.add("show");
      const intervalId = setInterval(() => {
        element.classList.remove("show");
        clearInterval(intervalId);
      }, 2000);
    }
  }, []);

  return (
    <>
    <section className="credit-api-main">
    <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
      <div className="container">
          <div className="row">
              <div className="col-lg-3">
                  <IntrigrationLeftSec />
              </div>
              <div className="col-lg-9">
                  <div className="creadit-api-upper-wrapper">
                        <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                      <h1>Credit Card API</h1>
                      <ul className="card-url">
                          <li>
                              <div className="card-url-list">
                                   <p>Sandbox endpoint URL:</p>
                                   <span><Link to=''>{`${config.gatewayApisUrlSand}/apis/{method}`}</Link></span>
                              </div>
                            
                          </li>
                          
                          <li>
                              <div className="card-url-list">
                                  <p>Example:</p>
                                  <span><Link to=''>{config.gatewayApisUrlSand}/apis/sale.php</Link></span>
                              </div>
                          </li>
                          <li>
                              <div className="card-url-list">
                                  <p>Production endpoint URL:</p>
                                  <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                              </div>
                          </li>
                      </ul>

                      
                  </div>

                  <div className="creadit-api-lower-wrapper">
                      <div className="api-menu-item-title">
                          <h2>Apple Pay</h2>
                      </div>
                      <div className="api-menu-item-wrapper">
                          <h3>Apple Pay API</h3>
                          <hr />
                          <p>This API is responsible for creating new payment transactions using Apple Pay.</p>
                          <ul className="card-url api-menu-url">
                              <li>
                                  <div className="card-url-list">
                                       <p>URL to call</p>
                                       <span><Link to=''>{config.gatewayApisUrlSand}/apis/apple_sale.php</Link></span>
                                  </div>
                                
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Method of form submission</p>
                                      <span><Link to=''>POST</Link></span>
                                 </div>
                              </li>
                              <br />

                              <p>Integrating Apple Pay to your application is a three step process, first call the javascript, display the Apple Pay button and finally send the response to processing API. All the steps are described below.</p>
                              <li>
                                  <div className="card-url-list">
                                       <p>Proxynization APIs</p>
                                  </div>
                                
                              </li>
                              <li>
                                  <div className="card-url-list">
                                       <p>Sandbox </p>
                                       <span><Link to=''>{config.gatewayApisUrlSand}/services/applePaySandbox.js</Link></span>
                                  </div>
                                
                              </li>
                              <li>
                                  <div className="card-url-list">
                                      <p>Production</p>
                                      <span><Link to=''>{config.gatewayApisUrlProd}/services/applePay.js</Link></span>
                                 </div>
                              </li>
                              
                          </ul>

                          
                      </div>
                      
                  </div>

                  <div className="example-box-wrapper mb-50">

                  <p>TCB Pay will provide apple-developer-merchantid-domain-association.txt file. This file must be uploaded to a folder called /.well-known/ at the document root of the web server.This is required to verify the domain.<br/><br/></p>

                      <div className="d-flex align-items-start">
                          <b>1 </b>
                          <p>Include applePay.js script file. Please copy the following lines and paste them to your HTML page to enable the Apple Pay functionality within your application.
                          <span>Example</span>
                          </p>
                      </div>
                      
                      
                      <div className="example-box">
                        <div className="example-box-top">
                        <div className="tooltip1" id="myTooltip1">
                          <span className="tooltiptext">Copied</span>
                        </div>
                        <CopyToClipboard onCopy={() => onCopy(1)} text={exampleCodeText_1}>
                          <FontAwesomeIcon icon={faCopy} />
                        </CopyToClipboard>
                        
                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            
                        </div>

                        <div className="ex-text-box" id="ExampleCodeText_1">
                            <pre>{exampleCodeText_1}</pre>
                        </div>
  
                      </div>

                  </div>

                  <div className="example-box-wrapper mb-50">
                      <div className="d-flex align-items-start">
                          <b className="">2 </b>
                          <p>Display an Apple Pay button: The example code below is to display Apple Pay button. Set the style and locale where you add the button.
                          <span>Example</span>
                          </p>
                      </div>
                      
                     
                      <div className="example-box">
                        <div className="example-box-top">
                        <div className="tooltip1" id="myTooltip2">
                          <span className="tooltiptext">Copied</span>
                        </div>
                        <CopyToClipboard onCopy={() => onCopy(2)} text={exampleCodeText_2}>
                          <FontAwesomeIcon icon={faCopy} />
                        </CopyToClipboard>
                        
                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            
                        </div>

                        <div className="ex-text-box" id="ExampleCodeText_2">
                            <pre>{exampleCodeText_2}</pre>
                        </div>
  
                      </div>
                  </div>

                  <div className="example-box-wrapper mb-50">
                      <div className="d-flex align-items-start">
                          <b className="me-3">3</b>
                          <p>Implement the request and response function. The TCBPayApplePay.paymentRequest function will be called when click on Apple Pay Button. This function accepts 4 parameters (app_key,business_name,amount,currency). The TCBPayApplePay.paymentResponse function will be invoked once the Apple Pay response is returned from our system. This function has 2 parameters (responseStatus,responseText). Parameter responseText is used as an input parameter to apple_sale.php API. Value of responseStatus is S for success.
                          </p>

                      </div>
                  </div>

                  <div className="api-table">
                      <h5>This table describes the request parameters for Apple pay js.</h5>

                      <div className="table-responsive">
                          <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: '145px' }}>Variable Name</th>
                                <th style={{ width: '420px' }}>Description</th>
                                <th style={{ width: '100px' }}>Required</th>
                                <th style={{ width: '130px' }}>Expected Values</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>app_key</td>
                                <td>This is MD5 of TCB Pay Gateway Username and 16 digit Secret key</td>
                                <td>Y</td>
                                <td>If Username is test and Secret key is abcdefgh12345678 app_key=MD5(testabcdefgh12345678)</td>
                              </tr>
                              <tr>
                                <td>business_name</td>
                                <td>Display this name in Apple Pay window</td>
                                <td>Y</td>
                                <td>Test shop</td>
                              </tr>
                              
                              <tr>
                                <td>amount</td>
                                <td>Amount to be charged</td>
                                <td>Y</td>
                                <td>100.00</td>
                              </tr>
                              <tr>
                                <td>currency</td>
                                <td>Currency of Transaction</td>
                                <td>Y</td>
                                <td>USD</td>
                              </tr>                           
                            </tbody>
                          </table>
                      </div>

                      <div className="example-box-wrapper">
                          <div className="d-flex align-items-start">
                              <b></b>
                             <p><span>Example</span></p>
                          </div>
                          <div className="example-box">
                        <div className="example-box-top">
                        <div className="tooltip1" id="myTooltip3">
                          <span className="tooltiptext">Copied</span>
                        </div>
                        <CopyToClipboard onCopy={() => onCopy(3)} text={exampleCodeText_3}>
                          <FontAwesomeIcon icon={faCopy} />
                        </CopyToClipboard>
                        
                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            
                        </div>

                        <div className="ex-text-box" id="ExampleCodeText_3">
                            <pre>{exampleCodeText_3}</pre>
                        </div>
  
                      </div>
                      </div>
                  </div>

                  <div className="api-table mt-60">
                      <h5>This table describes the request parameters for Authorize API.</h5>

                      <div className="table-responsive">
                                  <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <th>Variable Name</th>
                                        <th>Description</th>
                                        <th>Required</th>
                                        <th>Expected Values</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>username</td>
                                        <td>TCB Pay Gateway Username</td>
                                        <td>Y</td>
                                        <td>TCB Pay Gateway Username</td>
                                      </tr>
                                      <tr>
                                        <td>api_key</td>
                                        <td>TCB Pay Gateway API Key</td>
                                        <td>Y</td>
                                        <td>TCB Pay Gateway API Key</td>
                                      </tr>
                                      <tr>
                                        <td>signature_token</td>
                                        <td>Get a token from TCBPayApplePay.paymentResponse</td>
                                        <td>Y</td>
                                        <td>{`{"identifyKey":"test","data":"test",<br />"ephemeralPublicKey":"test","publicKeyHash":"test",<br />"transactionId":"test","signature":"test",<br />"version":"EC_v1","displayName":"MasterCard 1111",<br />"network":"MasterCard","type":"credit"}`}</td>
                                      </tr>
                                      <tr>
                                        <td>company_name</td>
                                        <td>Company Name of the cardholder</td>
                                        <td>N</td>
                                        <td>-</td>
                                      </tr>
                                      <tr>
                                        <td>first_name</td>
                                        <td>First name of the cardholder</td>
                                        <td>Y</td>
                                        <td>Roberto</td>
                                      </tr>
                                      <tr>
                                        <td>last_name</td>
                                        <td>Last name of the cardholder</td>
                                        <td>Y</td>
                                        <td>Diaz</td>
                                      </tr>
                                      <tr>
                                        <td>address</td>
                                        <td>Address of the cardholder</td>
                                        <td>N</td>
                                        <td>4446 Green Avenue, Suite 56</td>
                                      </tr>
                                      <tr>
                                        <td>city</td>
                                        <td>City of the cardholder</td>
                                        <td>N</td>
                                        <td>Los Angeles</td>
                                      </tr>
                                      <tr>
                                        <td>state</td>
                                        <td>State of the cardholder</td>
                                        <td>N</td>
                                        <td>CA</td>
                                      </tr>
                                      <tr>
                                        <td>country_code</td>
                                        <td>Country of the cardholder(ISO alpha-2)</td>
                                        <td>Y</td>
                                        <td>US, CN</td>
                                      </tr>
                                      <tr>
                                        <td>zip_code</td>
                                        <td>Zip code of the cardholder</td>
                                        <td>Y</td>
                                        <td>94085</td>
                                      </tr>
                                      <tr>
                                        <td>phone_number</td>
                                        <td>Phone Number of the cardholder</td>
                                        <td>N</td>
                                        <td>408-345-2323</td>
                                      </tr>
                                      <tr>
                                        <td>email</td>
                                        <td>Email ID of the cardholder</td>
                                        <td>Y</td>
                                        <td>test@test.com</td>
                                      </tr>
                                      <tr>
                                        <td>email_receipt</td>
                                        <td>Sends transaction details to specified cardholder email </td>
                                        <td>N</td>
                                        <td>Y or N (default value N) 
                                          Y- Send email 
                                          N- Not send
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>emailReceiptDBAEmail</td>
                                        <td>Email Receipt to Merchant DBA Contact Email  </td>
                                        <td>N</td>
                                        <td>Y or N (default value N) 
                                          Y- Send email 
                                          N- Not send
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>memo</td>
                                        <td>Memo of the transaction </td>
                                        <td>N</td>
                                        <td>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>client_ip</td>
                                        <td>Client IP address</td>
                                        <td>N</td>
                                        <td>127.0.0.1</td>
                                      </tr>
                                      <tr>
                                        <td>custom_field_1</td>
                                        <td></td>
                                        <td>N</td>
                                        <td>additional parameter 1</td>
                                      </tr>
                                      <tr>
                                        <td>custom_filed_2</td>
                                        <td></td>
                                        <td>N</td>
                                        <td>additional parameter 2</td>
                                      </tr>
                                     
                                    </tbody>
                                  </table>
                                </div>

                      <div className="example-box-wrapper">
                          <div className="d-flex align-items-start">
                              <b></b>
                             <p><span>Example</span></p>
                          </div>
                          <div className="example-box">
                            <div className="example-box-top">
                            <div className="tooltip1" id="myTooltip4">
                              <span className="tooltiptext">Copied</span>
                            </div>
                            <CopyToClipboard onCopy={() => onCopy(4)} text={exampleCodeText_4}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                            
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                
                            </div>

                            <div className="ex-text-box" id="ExampleCodeText_4">
                                <pre>{exampleCodeText_4}</pre>
                            </div>
      
                          </div>
                      </div>
                  </div>
                  
                      <div className="api-menu-item-wrapper mt-60">
                          <h3>Response</h3>
                          <hr />
                          <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, four elements are returned, code, description, transaction_id, total_amount. The response code is always 00 for a successful API call.</p>
                          <div className="example-box-wrapper mb-20">
                              <div className="d-flex align-items-start">
                                  <b></b>
                                  <span>Sample Response</span>
                              </div>
                              <div className="example-box">
                                <div className="example-box-top">
                                <div className="tooltip1" id="myTooltip5">
                                  <span className="tooltiptext">Copied</span>
                                </div>
                                <CopyToClipboard onCopy={() => onCopy(5)} text={exampleCodeText_5}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                                
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                    
                                </div>

                                <div className="ex-text-box" id="ExampleCodeText_5">
                                    <pre>{exampleCodeText_5}</pre>
                                </div>
          
                              </div>
                          </div>
                      </div>
               
                   


              </div>
          </div>
      </div>
      <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
      <ScrollToTop smooth />
    </section>
    </>
  )
}
