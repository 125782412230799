import React,{useState,useEffect} from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import config from '../Config';

import { IntegrantionModuleService } from '../services/IntegrantionModuleService';

interface IState {
    loading: boolean;
    module: any;
    errorMsg: string;
  }

export const ZenCartSec = () => {
    const exampleCodeText_1 = `function zen_cfg_pull_down_tcbpay_cctypes($cctypes, $key = '') {
    $name = 'configuration[' . $key . '][]';

    $cctypes_array = array(
    array(
        'id' => 'AE',
        'text' => MODULE_PAYMENT_TCBPAY_TEXT_AMERICAN_EXPRESS
    ), array(
        'id' => 'VI',
        'text' => MODULE_PAYMENT_TCBPAY_TEXT_VISA
    ),  array(
        'id' => 'MC',
        'text' => MODULE_PAYMENT_TCBPAY_TEXT_MASTERCARD
    ), array(
        'id' => 'DI',
        'text' => MODULE_PAYMENT_TCBPAY_TEXT_DISCOVER
    ), array(
        'id' => 'JCB',
        'text' => MODULE_PAYMENT_TCBPAY_TEXT_JCB
    ), array(
        'id' => 'OT',
        'text' => MODULE_PAYMENT_TCBPAY_TEXT_OTHER
    )
    );

    return zen_draw_multi_select_menu($name, $cctypes_array, $cctypes);
}`;
    const exampleCodeText_2 = `function zen_draw_multi_select_menu($name, $values, $default = array(), $parameters = '', $required = false) {
    $field = '<select multiple rel="dropdown" name="'.zen_output_string($name).'"';

    if (zen_not_null($parameters)) {
    $field .= ' ' . $parameters;
    }

    $field .= '>' . "\n";

    if (empty($default) && isset($GLOBALS[$name])) {
    $default = $GLOBALS[$name];
    }

    if (!is_array($default)) $default = explode(',', $default);
    foreach($default as $dval) {
    $default[] = trim($dval);
    }

    foreach ($values as $value) {
    $field .= '<option value="' . zen_output_string($value['id']) . '"';
    if (in_array($value['id'], $default)) {
        $field .= ' selected="selected"';
    }

    $field .= '>' . zen_output_string($value['text'], array('"' => '"', '\'' => ''', '<' => '<', '>' => '>')) . 'option>' . "\n";
    }
    $field .= 'select>' . "\n";

    if ($required == true) {
    $field .= TEXT_FIELD_REQUIRED;
    }

    return $field;
}
                
  `;
    const onCopy = React.useCallback((id: number) => {
        const element = document.getElementById("myTooltip" + id);
        if (element) {
            element.classList.add("show");
            const intervalId = setInterval(() => {
                element.classList.remove("show");
                clearInterval(intervalId);
            }, 2000);
        }
    }, []);



    const [state, setState] = useState<IState>({
        loading: false,
        module: [] as any,
        errorMsg: "",
    });

    //network request
    useEffect(() => {
        setState({ ...state, loading: true });
        IntegrantionModuleService.getModuleDetails('ZenCart')
        .then((res) =>{
        setState({
            ...state,
            loading: false,
            module: res.data as any,
        })

        }
        
        )
        .catch((err) =>{
        setState({
            ...state,
            loading: false,
            errorMsg: err.message,
        })

        }
        );
    //eslint-disable-next-line
    }, []);


    const { loading, module, errorMsg } = state;
    const modDetails = module.response?.module_details;
    const latestVersion = module.response?.module_details.length > 0 ? module.response.module_details[0] : [];
    const contentDetArr = module.response?.module_details.length > 0 ? module.response.module_details[0].jsoncontent : [];

    return (
        <>
            <section className="credit-api-main">
                <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <IntrigrationLeftSec />
                        </div>
                        <div className="col-lg-9">
                            <div className="creadit-api-upper-wrapper">
                                <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                                <h1>Zen Cart</h1>

                                <ul className="card-url">
                                    {Object.entries(contentDetArr).map(([keyName, keyValue]: [any, any]) => (
                                        <>
                                        <li>
                                            <div className="card-url-list">
                                                <p>{keyName}:</p>
                                                <span>{keyValue}</span>
                                            </div>
                                        </li>
                                        </>
                                    ))}
                                </ul>
                                <p className='mt-30'>This plugin allows you to use the TCB Pay payment portal for your Zen Cart, as an additional payment option.</p>
                            </div>

                            <div className="creadit-api-lower-wrapper">
                                {/* <div className="api-menu-item-title">
                            <h2>Installation</h2>
                        </div> */}
                                <div className="api-menu-item-wrapper">
                                    <h3>Installation</h3>
                                    <hr />
                                    <Link to={`${config.pluginModuleUrl}/${latestVersion.download_url}/${latestVersion.file_name}`} target='_blank' className="integration-btn1 mb-20">Download the Zen Cart extension package <FontAwesomeIcon icon={faArrowRight} /></Link>
                                    {modDetails?.length > 1 &&
                                        <>
                                            <p>Download older versions of the package:</p>
                                            <div className="listintri">
                                                <ul>
                                                {modDetails?.map((keyName: any) => (
                                                    <>
                                                        <li>Download <Link to={`${config.pluginModuleUrl}/${keyName.download_url}/${keyName.file_name}`} target='_blank'>{keyName.version_name}</Link> 
                                                        {keyName.dependency ? `for ${keyName.dependency}` : ""}</li>
                                                    </>
                                                    ))
                                                }
                                                </ul>
                                            </div>
                                        
                                        </>
                                    }

                                </div>
                            </div>

                            <div className="example-box-wrapper mt-100 mb-30">
                                <div className="d-flex align-items-start">
                                    <b>1 </b>
                                    <p>Copy all new files within your catalog. It won’t rewrite any of your files. Just copy & paste on top.</p>
                                </div>
                            </div>

                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>2 </b>
                                    <p>Modify the following files as per the following instructions.</p>
                                </div>
                            </div>
                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <p>Installation Instructions:
                                        <span className='newintretxt'>Find admin/includes/functions/html_output.php file. Add below code at the end. <br />
                                            <b>First step:</b></span>
                                    </p>
                                </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                        <div className="tooltip1" id="myTooltip1">
                                            <span className="tooltiptext">Copied</span>
                                        </div>
                                        <CopyToClipboard onCopy={() => onCopy(1)} text={exampleCodeText_1}>
                                            <FontAwesomeIcon icon={faCopy} />
                                        </CopyToClipboard>

                                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />

                                    </div>

                                    <div className="ex-text-box bighgt" id="ExampleCodeText_1">
                                        <pre>{exampleCodeText_1}</pre>
                                    </div>

                                </div>

                            </div>

                            <div className="example-box-wrapper mb-50">
                                <div className="d-flex align-items-start">
                                    <p>
                                        <span className='newintretxt'>Find admin/includes/functions/html_output.php file. Add below code at the end.  <br />
                                            <b>Second step:</b></span>
                                    </p>
                                </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                        <div className="tooltip1" id="myTooltip2">
                                            <span className="tooltiptext">Copied</span>
                                        </div>
                                        <CopyToClipboard onCopy={() => onCopy(2)} text={exampleCodeText_2}>
                                            <FontAwesomeIcon icon={faCopy} />
                                        </CopyToClipboard>

                                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />

                                    </div>

                                    <div className="ex-text-box bighgt" id="ExampleCodeText_2">
                                        <pre>{exampleCodeText_2}</pre>
                                    </div>

                                </div>
                            </div>

                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>3 </b>
                                    <p>In the admin side of your website go to Modules - Payment and install the new TCB Pay payment. Edit the payment put in credentials and set up your settings.</p>
                                </div>
                            </div>



                            <div className="api-menu-item-wrapper mt-60">
                                <h3>Manage Plugin</h3>
                                <hr />
                                <p>In the manage screen, you can set the following values:</p>
                                <div className='listintri'>
                                    <ul>
                                        <li><strong>Username:</strong> This is the username provided by TCB Pay when you signed up for an account.</li>
                                        <li><strong>API Key:</strong> This is the API Key provided by TCB Pay when you signed up for an account.</li>
                                        <li><strong>Credit Card Types:</strong> Select your accepted card.</li>
                                        <li><strong>Test Mode:</strong> If true TCB Test Pay is enabled. If false TCB test Pay is disabled.</li>
                                    </ul>
                                </div>
                                <img src={`${config.siteBaseUrl}/assets/imgs/zenCart.png`} alt='' />
                            </div>




                        </div>
                    </div>
                </div>
                <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`} alt="" className="orng-dots" />

                <ScrollToTop smooth />
            </section>
        </>
    )
}
