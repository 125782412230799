import React from 'react'
import { Banner } from './banner'
import { Whitelabel_product } from './whitelabel_product'

export const WhiteLabel = () => {
  return (
    <>
    <Banner />
    <Whitelabel_product/>

    </>
  )
}
