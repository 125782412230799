import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

export const HighriskSec = () => {
  return (
    <>
    <section className="highrisk-bg">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <h6 className="seamless-heading abut-txt text-left">High-risk industries we support</h6>
                </div>
                
                    <div className="tracking-facility text-left order-none">

                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon1.svg" alt="" />
                                <h6>CBD/Vape/Hemp</h6>
                                <p>Facilitate payments for CBD, vape, and hemp products securely. Comply with industry regulations in payment processing.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon2.svg" alt="" />
                                <h6>Credit Repair</h6>
                                <p>Offer transparent payment options for credit repair services. Build trust with secure and discreet billing.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon3.svg" alt="" />
                                <h6>Cryptocurrency</h6>
                                <p>Navigate payments in the complex world of cryptocurrency. Our platform ensures secure, efficient transactions.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon4.svg" alt="" />
                                <h6>Digital Goods</h6>
                                <p>Monetize your digital goods with flexible payment options. Provide instant, secure transactions for digital content.</p>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon5.svg" alt="" />
                                <h6>Electronics</h6>
                                <p>Securely accept payments for all electronic goods. Benefit from fraud protection and fast processing.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon6.svg" alt="" />
                                <h6>Gaming</h6>
                                <p>Power up your gaming platform with a secure payment gateway. Accept payments globally with low latency.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon7.svg" alt="" />
                                <h6>Money Transmitter</h6>
                                <p>Experience seamless, uninterrupted payment processing. Maximize revenue with flexible payment solutions.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon8.svg" alt="" />
                                <h6>Pharma/Nutra</h6>
                                <p>Reliable payment solutions for the pharma and nutraceutical industry. Ensure compliance and customer safety.</p>
                            </div>
                        </div>


                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon9.svg" alt="" />
                                <h6>Tech Support</h6>
                                <p>Support your tech services with dedicated payment solutions. Offer instant billing for technical assistance.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon10.svg" alt="" />
                                <h6>Telemedicine</h6>
                                <p>Simplify patient billing with secure online transactions. Enhance care with easy payment processing.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon11.svg" alt="" />
                                <h6>Travel</h6>
                                <p>Transform travel payments with easy booking transactions. Offer travelers a variety of payment methods.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon12.svg" alt="" />
                                <h6>Warranties</h6>
                                <p>Enhance your warranty services with trusted payment processing. Provide customers with hassle-free payments.</p>
                            </div>
                        </div>


                    </div> 
                    <div className="tracking-facility text-left order-block">

                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon1.svg" alt="" />
                                <h6>CBD/Vape/Hemp</h6>
                                <p>Facilitate payments for CBD, vape, and hemp products securely. Comply with industry regulations in payment processing.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon2.svg" alt="" />
                                <h6>Credit Repair</h6>
                                <p>Offer transparent payment options for credit repair services. Build trust with secure and discreet billing.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon3.svg" alt="" />
                                <h6>Cryptocurrency</h6>
                                <p>Navigate payments in the complex world of cryptocurrency. Our platform ensures secure, efficient transactions.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon4.svg" alt="" />
                                <h6>Digital Goods</h6>
                                <p>Monetize your digital goods with flexible payment options. Provide instant, secure transactions for digital content.</p>
                            </div>
                        </div>

                        
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon6.svg" alt="" />
                                <h6>Gaming</h6>
                                <p>Power up your gaming platform with a secure payment gateway. Accept payments globally with low latency.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon5.svg" alt="" />
                                <h6>Electronics</h6>
                                <p>Securely accept payments for all electronic goods. Benefit from fraud protection and fast processing.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon8.svg" alt="" />
                                <h6>Pharma/Nutra</h6>
                                <p>Reliable payment solutions for the pharma and nutraceutical industry. Ensure compliance and customer safety.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon7.svg" alt="" />
                                <h6>Money Transmitter</h6>
                                <p>Experience seamless, uninterrupted payment processing. Maximize revenue with flexible payment solutions.</p>
                            </div>
                        </div>
                        


                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon9.svg" alt="" />
                                <h6>Tech Support</h6>
                                <p>Support your tech services with dedicated payment solutions. Offer instant billing for technical assistance.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon10.svg" alt="" />
                                <h6>Telemedicine</h6>
                                <p>Simplify patient billing with secure online transactions. Enhance care with easy payment processing.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon11.svg" alt="" />
                                <h6>Travel</h6>
                                <p>Transform travel payments with easy booking transactions. Offer travelers a variety of payment methods.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="tracking-inr-box w-100">
                                <img src="assets/imgs/h-icon12.svg" alt="" />
                                <h6>Warranties</h6>
                                <p>Enhance your warranty services with trusted payment processing. Provide customers with hassle-free payments.</p>
                            </div>
                        </div>


                    </div> 
            </div>
        </div>     
    </section>


    <section className="featuresbx">
        <div className="container">
            <div className='row'>
                <div className='col-md-7'>
                    <h6 className='seamless-heading'>Empowering high-risk businesses with tailored solutions</h6>
                </div>
            </div>

            <div className="featuresbx-inr">
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                            <div className='imginerdiv'><img src="assets/imgs/h-icon13.svg" alt="" /></div>
                            <h4>Tailored Solutions</h4>
                            <p>Customized services to meet the unique needs of high-risk businesses.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                            <div className='imginerdiv'><img src="assets/imgs/h-icon14.svg" alt="" /></div>
                            <h4>Risk Management Expertise</h4>
                            <p>Advanced tools and strategies to mitigate fraud and financial losses.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                            <div className='imginerdiv'><img src="assets/imgs/h-icon15.svg" alt="" /></div>
                            <h4>Regulatory Compliance</h4>
                            <p>Ensuring adherence to industry regulations and minimizing risk.</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="inr-box innerblue">
                            <div className='imginerdiv'><img src="assets/imgs/h-icon16.svg" alt="" /></div>
                            <h4>Dedicated Support</h4>
                            <p>24/7 customer assistance for guidance and issue resolution.</p>
                        </div>
                    </div>
                    <div className='col-md-12 mt-60'>
                        <Link to={`${config.siteBaseUrl}/assets/pdf/High-Risk-Sell-Sheet.pdf`} target='blank' className="integration-btnhr text-center m-auto">Download High Risk Sell Sheet <FontAwesomeIcon icon={faArrowRight} /> </Link>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>


    <section className='itsolution-contact high-riskbg'>
        
        <div className='container'>
            <div className='row justify-content-md-center'>
               <div className='col-md-12 position-relative'>
                <img src="assets/imgs/h-icon17.svg" alt="" className='itsolution-icn5'/>
                <img src="assets/imgs/h-icon18.svg" alt="" className='itsolution-icn6'/>
                <div className='it-contact'>
                  <h6 className='seamless-heading'>Ever been denied by other payment processors?</h6>
                  <p className='mb-0 pb-0'><b>Finding reliable payment solutions for high-risk businesses is a challenge.</b></p> 
                  <p>At TCB Pay, we specialize in assisting high-risk businesses like yours. Our underwriting and <br /> risk team specialists are here to explore alternative solutions and find a way to make things<br/> work for you. Don’t settle for limitations. Choose TCB Pay and unlock the possibilities for<br/> your high-risk business!</p>
                  <Link to='https://calendly.com/chris-tcb/high-risk' className="integration-btn1 m-auto" target='blank'>Schedule a free consultation now <FontAwesomeIcon icon={faArrowRight} /></Link>  
                </div>
                
                <img src="assets/imgs/h-icon19.svg" alt="" className='itsolution-icn7'/>
                <img src="assets/imgs/h-icon20.svg" alt="" className='itsolution-icn8'/>
                </div> 
            </div>
        </div>
    </section>
    <section>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="blue-dots2" />
    </section>
    </>
  )
}
