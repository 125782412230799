import React, { useEffect, forwardRef } from 'react';
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export const Payportal = forwardRef((props: any, ref: React.Ref<HTMLDivElement>) => {
    useEffect(() => {
        AOS.init();
      }, []);
  return (
    
    <>
    <section className='tcbpayapp'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 order-sm-2 order-lg-1'>
                    <img src='assets/imgs/groupportal.svg' alt="" className='papp-pic' />
                </div>
                <div className='col-md-6 d-flex align-items-center order-sm-1 order-lg-2'>
                    <div className="our-integration-inner p-80"><h6>TCB Pay <span>Portal</span></h6>
                    <p>A TCB Pay account also gives you access to the TCB Pay Portal. This all-in-one payment portal offers a wide range of features to streamline your business operations.</p>
                    <Link to='/portal' className="integration-btn mt-30">Discover the TCB Pay Portal <FontAwesomeIcon icon={faArrowRight} /></Link>
                    </div>
                </div>
            </div>
        </div>
    </section>

    

    <section className="payportal-area gradient-background3">
        <div className="wave7 d-lg-block">
            <img src="assets/imgs/wave-7.png" alt="" />
        </div>
        
        <div className="container">
            <div className="row">
                <div className="col-lg-12 mb-40 text-left">
                <h6 className="seamless-heading">Honest <span style={{color:'#397AB7'}}>pricing</span> for seamless payments</h6>
                </div> 
                <div className='col-md-8'>
                    <div className='honest-lftbx'>
                        <div className='honest-lftbx-inner'>
                            <h6>Card-Present</h6>
                            <p>For business that is made in-person</p>
                            <div className='pricetxt'>2.75% + $0.20</div>
                            <span>Per transaction</span>
                        </div>
                        <div className='honest-lftbx-inner border-0'>
                            <h6>Card-Not-Present</h6>
                            <p>For business that is made online</p>
                            <div className='pricetxt'>3.5% + $0.20</div>
                            <span>Per transaction</span>
                        </div>
                    </div>
                    <Link to="/pricing" className='pricingdetail-btn'>View pricing details <FontAwesomeIcon icon={faArrowRight} /></Link>
                </div>
                <div className='col-md-4 position-relative'>
                    <div className='honest-rgtbx'>
                        <h4>High-Risk & Custom Pricing</h4>
                        <p>If you have a unique business model, reach out to us and we will find the best solutions for your business.</p>
                        <Link to="/contact" className='letstalk'>Let’s talk</Link>
                    </div>
                    <img src='assets/imgs/bluedots1.svg' alt='' className='blue-dots22' />
                </div>  
            </div>
        </div>

        <div className="wave8 d-lg-block">
        <img src="assets/imgs/wave-8.png" alt="" />
        </div>
        
    </section>

    <section ref={ref} className='itsolution-contact newtcbpayapp'>
        
        <div className='container'>
            <div className='row justify-content-md-center'>
               <div className='col-md-12 position-relative'>
                <img src="assets/imgs/tcbapp-icn1.svg" alt="" className='newtcbpayapp-icn1'/>
                <img src="assets/imgs/tcbapp-icn2.svg" alt="" className='newtcbpayapp-icn2'/>
                <div className='it-contact'>
                  <h6 className='seamless-heading'>Get the TCB Pay App</h6>
                  <p>Access your TCB Pay account anytime, anywhere. Download the app to manage <br/>transactions, monitor activity, and stay in control of your business—all in one place.</p>
                  <div className='col-md-5 m-auto d-flex appplayicn'>
                    <Link to='https://apps.apple.com/es/app/tcb-pay/id1490827069' className="m-auto" target='blank'><img src="assets/imgs/apple-store.svg" alt="" /></Link>
                    <Link to='https://play.google.com/store/apps/details?id=com.tcbpay' className="m-auto" target='blank'><img src="assets/imgs/google-play.svg" alt="" /></Link>  
                  </div>
                  
                </div>
                
                <img src="assets/imgs/tcbapp-icn3.svg" alt="" className='newtcbpayapp-icn3'/>
                <img src="assets/imgs/tcbapp-icn4.svg" alt="" className='newtcbpayapp-icn4'/>
                </div> 
            </div>
        </div>
    </section>

    {/* <canvas id="gradient-canvas" ></canvas> */}

    </>
  );
});
