import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = () => {
  return (
    <>
    <section className='banner-bg min-heght alert'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <Col xs lg="5" className='text-left m-new'>
            <span></span>
            <h2>Alerts and Notifications</h2>
            <p>The Alerts and Notifications system is a security feature provided by ComData (our card issuer partner), that allows you to authorize or deny transactions that are flagged as being suspicious as well as notify you of declined transactions that are non-fraudulent (e.g. an incorrect PIN number) via text message from your mobile phone.</p>
            </Col>
            <Col xs lg="1" className='mbdnone'></Col>
            <Col xs lg="6">
            <img src='assets/imgs/alert.png' alt='' className='banner2' style={{ width: '320px',}} />
            </Col>
          </Row>    
        </Container>

    </section>
    </>
  )
}
