import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import SplitButton from 'react-bootstrap/SplitButton';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {faCircleArrowRight} from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

import {Helmet} from "react-helmet";
type seoMetaData = {
    urlkey:string,
    title:string,
    pageurl:string,
    pageimage:string,
    description:string,
    keywords:string
  };

export const Header = () => {

    const location = useLocation();
    const currentUrl = window.location.href;
    const segment_1 = location.pathname.split('/')[1];
    const segment_2 = location.pathname.split('/')[2];
    const segment_3 = location.pathname.split('/')[3];

    const [metaJson, setMetaJson] = useState<any>([]);
    useEffect(() => {
        fetch(`${config.siteBaseUrl}/metadata.json`)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            setMetaJson(data);
        });
    },[]);

    const [seoMetaData, setSeoMetaData] = useState<seoMetaData>({
        urlkey:'',
        title:'',
        pageurl:currentUrl,
        pageimage:'',
        description:'',
        keywords:''
    });

    if(segment_2 !== undefined && segment_1!='faq'){
        var srcurlkey = `${segment_1}/${segment_2}`;
    } else {
        var srcurlkey = segment_1;
    }

    useEffect(() => {

        const pageMetaObj = metaJson.find((el:any)=>{
            return el.urlkey==srcurlkey;
        }) || {
            "urlkey": "",
            "title": "TCB Pay",
            "pageimage": "https://www.tcbpay.com/images/rich_link_home.png",
            "description": "Seamless payment integrations. Developer resources, API docs, guides, and powerful tools. Simplify payment with SDKs, and robust APIs. Customer support",
            "keywords": "Payment processing, Mobile payments, Retail solutions, Payment gateway, Online payments, Merchant services, Payment solutions, Contactless payments, Secure transactions, Business tools"
        };

        setSeoMetaData({
            urlkey:'',
            title:`TCB Pay | ${pageMetaObj.title}`,
            pageurl:currentUrl,
            pageimage:pageMetaObj.pageimage,
            description:pageMetaObj.description,
            keywords:pageMetaObj.keywords
          });
    });


    const [drawerStates, setDrawerStates] = React.useState({
        isOpen1: false,
        isOpen2: false,
        isOpen3: false,
      });

      const toggleDrawer = (id: string) => {
        setDrawerStates((prevState:any) => ({
          ...prevState,
          [id]: !prevState[id],
        }));
      };

      const closeMenu = () => {
        const element1 = document.getElementById("offcanvasNavbar-expand-md mainMenuId1");
        if (element1) { element1.classList.remove("show"); }

        const element2 = document.getElementById("offcanvasNavbar-expand-md mainMenuId2");
        if (element2) { element2.classList.remove("show"); }

        const element3 = document.getElementById("offcanvasNavbar-expand-md mainMenuId3");
        if (element3) { element3.classList.remove("show"); }
        setDrawerStates((prevState:any) => ({
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
          }));
      }

    return (
        <>
            <Helmet>             
                <title>{seoMetaData.title}</title>
                <meta property="og:title" content={seoMetaData.title} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={seoMetaData.pageurl} />
                <meta property='og:image' content={seoMetaData.pageimage}/>
                <meta name="description" content={seoMetaData.description}></meta>
                <meta name="keywords" content={seoMetaData.keywords}></meta>
            </Helmet>

            {['md'].map((expand) => (
                <Navbar key={expand} expand={expand} fixed="top" className="mb-3 mt-2">
                    <Container className='menu-hidden-sm'>
                        <Navbar.Brand href={config.siteBaseUrl}><img src={`${config.siteBaseUrl}/assets/imgs/header-logo.svg`} alt="Header Logo" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand} mainMenuId1`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    Offcanvas
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <NavDropdown title="Products" id="navbarScrollingDropdown" align="start" className='megamenu border-custm whtclr'>
                                        <Row>
                                            <Col xs md="6">
                                                <div className='megamenubg'>
                                                    <div className='menutitle'>PAYMENT PROCESSING</div>
                                                    <Row>
                                                    <Col xs md="6">
                                                    <NavDropdown.Item as={Link} to="portal"><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon1.svg`} alt="TCBPortal" />TCB Pay Portal
                                                    <span>Your all-in-one payment processing solution.</span>
                                                    </NavDropdown.Item>
                                                    <div className='menu-inner'>
                                                    <NavDropdown.Item as={Link} to="app"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="TCBPay" />TCB Pay App
                                                    <span>For on-the-go businesses.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to="retail"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Retail" />Retail
                                                    <span>In-store payment solutions.</span>
                                                    </NavDropdown.Item>
                                                    </div>
                                                    
                                                    </Col>
                                                    
                                                    {/* <Col xs md="1"></Col> */}
                                                    <Col xs md="6">
                                                    <NavDropdown.Item as={Link} to='ach-processing'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon19.svg`} alt="ACH" />ACH Processing
                                                    <span>Automate electronic payments between banks.</span>
                                                    </NavDropdown.Item>
                                                    <div className='menu-inner'>
                                                    <NavDropdown.Item as={Link} to="ecommerce"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="E-commerce" />E-commerce
                                                    <span>Online business solutions.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to="high-risk"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Partners" />High-Risk
                                                    <span>Secure transactions for all.</span>
                                                    </NavDropdown.Item>
                                                    </div>
                                                    </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col xs md="3" className='p-0'>
                                                <div  className='megamenubg spchgt'>
                                                <div className='menutitle yelw'>CORPORATE CARDS</div>
                                                    <NavDropdown.Item as={Link} to='issuing'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon3.svg`} alt="Pay Issuing" />TCB Pay Issuing
                                                    <span>Credit cards with prepaid and postpaid capabilities.</span>
                                                    </NavDropdown.Item>
                                                    <div className='menu-inner'>
                                                    <NavDropdown.Item as={Link} to='a-n'><img src={`${config.siteBaseUrl}/assets/imgs/m-arw1.svg`} alt="Alert" />Alert & Notifications
                                                    <span>Real-time alerts.</span>
                                                    </NavDropdown.Item>
                                                    </div>
                                                    
                                                </div>
                                            </Col>
                                            <Col xs md="3" >
                                                <div  className='megamenubg techmu'>
                                                    <div className='menutitle'>TECH</div>
                                                    <NavDropdown.Item as={Link} to='white-label'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon22.svg`} alt="White-Label" />TCB Pay White-Label
                                                    <span>Customizable payment system for businesses.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to='integrations'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon20.svg`} alt="Integrations" />Integrations
                                                    <span>Connects your payment system with other platforms.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to='it-solutions'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon21.svg`} alt="it-solutions" />IT Solutions
                                                    <span>Tech services for smooth operations and security.</span>
                                                    </NavDropdown.Item>
                                                </div>
                                            </Col>
                                            
                                        </Row>
                                    </NavDropdown>

                                    <NavDropdown title="Resources" id="navbarScrollingDropdown1" className='megamenu border-custm whtclr'>
                                        <Row>
                                            <Col xs md="6">
                                                <div className='megamenubg'>
                                                    <div className='menutitle'>ABOUT TCB PAY</div>
                                                    <Row>
                                                    <Col xs md="6">
                                                    <NavDropdown.Item as={Link} to="about-us"><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon10.svg`} alt="About Us" />About Us
                                                    <span>Overview of our company values.</span>
                                                    </NavDropdown.Item>
                                                    <div className='menu-inner'>
                                                    <NavDropdown.Item as={Link} to="our-team"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Team" />Team
                                                    <span>Meet our dedicated team.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to="partners"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Partners" />Partners
                                                    <span>Collaborate with us.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to="careers"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Careers" />Careers
                                                    <span>Explore job opportunities.</span>
                                                    </NavDropdown.Item>
                                                    </div>
                                                    
                                                    </Col>
                                                    {/* <Col xs md="1"></Col> */}
                                                    <Col xs md="6" className='spchgt1'>
                                                    <NavDropdown.Item as={Link} to='pricing'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon14.svg`} alt="Pricing" />Pricing
                                                    <span>Review available service pricing plans.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to={config.blogUrl} target='blank'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon11.svg`} alt="Blog" />Blog
                                                    <span>Explore industry insights and updates.</span>
                                                    </NavDropdown.Item>
                                                    </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col xs md="3" className='p-0'>
                                                <div className='megamenubg spchgt'>
                                                    <div className='menutitle yelw'>TECH</div>
                                                    <NavDropdown.Item as={Link} to='security' className='tch2'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon15.svg`} alt="Menu Icon 14" />Security
                                                    <span>Learn about our security measures.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to='system-status' className='tch2'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon17.svg`} alt="Menu Icon 17" />System Status
                                                    <span>Check system performance and uptime.</span>
                                                    </NavDropdown.Item>
                                                </div>
                                            </Col>
                                            <Col xs md="3">
                                                <div  className='megamenubg'>
                                                <div className='menutitle'>GET HELP</div>
                                                    <NavDropdown.Item as={Link} to='contact'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon18.svg`} alt="Contact" />Contact Us
                                                    <span>Reach out for support or inquiries.</span>
                                                    </NavDropdown.Item>
                                                    <div className='menu-inner'>
                                                    <NavDropdown.Item as={Link} to='faq'><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="FAQ" />FAQ
                                                    <span>Questions and answers.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to='wiki-portal'><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Portal Wiki" />TCB Pay Portal Wiki
                                                    <span>A step-by-step guide.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to='wiki-issuing'><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Pay Issuing" />TCB Pay Issuing Wiki
                                                    <span>Understand the platform.</span>
                                                    </NavDropdown.Item>
                                                    </div>
                                                    
                                                </div>
                                            </Col>
                                        </Row>

                                    </NavDropdown>
                                    {/* <Nav.Link href="" className='signin'>Sign In</Nav.Link> */}
                                    <Dropdown className='signin-drp'>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className='signin'>
                                    Sign In
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                    <Dropdown.Item href="https://portal.tcbpay.com/" target='blank'><img src={`${config.siteBaseUrl}/assets/imgs/sm-icon.svg`} alt="" /> Sign in to TCB Pay Portal</Dropdown.Item>
                                    <Dropdown.Item href="https://issuing.tcbpay.com/" target='blank'><img src={`${config.siteBaseUrl}/assets/imgs/sm-icon1.svg`} alt="" /> Sign in to TCB Pay Issuing</Dropdown.Item>
                                    </Dropdown.Menu>
                                    </Dropdown>
                                    {/* <Nav.Link href="" className='consultatnt-btn'>Book your free consultation <FontAwesomeIcon icon="fas fa-arrow-right" /></Nav.Link> */}
                                    <Nav.Link href={`${config.siteBaseUrl}#show-calendly`} className='consultatnt-btn'>
                                        Book your free consultation <FontAwesomeIcon icon={faArrowRight} /> 
                                    </Nav.Link>
                                </Nav>

                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>


                    <Container className='d-block d-sm-none'>
                        <Navbar.Brand href="/tcbpay-website"><img src={`${config.siteBaseUrl}/assets/imgs/header-logo.svg`} alt="Header Logo" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand} mainMenuId2`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                <Navbar.Brand href="/tcbpay-website"><img src={`${config.siteBaseUrl}/assets/imgs/header-logo1.svg`} alt="Header Logo" /></Navbar.Brand>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className='mobile-v'>
                                <Nav className="justify-content-end flex-grow-1">

                                <div className='mobile-menuoffcanvas'>
                                <div className='signin' onClick={() => toggleDrawer('isOpen3')}>Sign In</div>
                                    <ul>
                                        <li onClick={() => toggleDrawer('isOpen1')}><span>Products</span></li>
                                        <li onClick={() => toggleDrawer('isOpen2')}><span>Resources</span></li>
                                        {/* <li><span to='ContactUs'>Contact</span></li> */}
                                        <NavDropdown.Item as={Link} to='contact' onClick={closeMenu} className='contct-mb'>Contact us</NavDropdown.Item>
                                    </ul> 
                                </div>


                                <Drawer
                                    open={drawerStates.isOpen1}
                                    direction='right'
                                    enableOverlay={false}
                                >
                                    
                                    <div className='navbar mb'>
                                        <div className='navbar-nav'>
                                            <Offcanvas.Header closeButton>
                                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                            <Navbar.Brand href="/tcbpay-website"><img src={`${config.siteBaseUrl}/assets/imgs/header-logo1.svg`} alt="Header Logo" /></Navbar.Brand>
                                            </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <button onClick={() => toggleDrawer('isOpen1')} className='back-mbbtn'><img src={`${config.siteBaseUrl}/assets/imgs/lft-arwmb.png`} alt='' />Back</button>
                                            <div className='megamenubg'>
                                                <div className='menutitle'>PAYMENT PROCESSING</div>
                                                <Row>
                                                <div className='col-md-12'>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to="portal"><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon1.svg`} alt="TCBPortal" />TCB Pay Portal
                                                <span>Your all-in-one payment processing solution.</span>
                                                </NavDropdown.Item>
                                                <div className='menu-inner'>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to="app"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="TCBPay" />TCB Pay App
                                                <span>For on-the-go businesses.</span>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to="retail"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Retail" />Retail
                                                <span>In-store payment solutions.</span>
                                                </NavDropdown.Item>
                                                </div>
                                                </div>

                                                <div className='col-md-12'>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to='ach-processing'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon19.svg`} alt="ACH" />ACH Processing
                                                <span>Automate electronic payments between banks.</span>
                                                </NavDropdown.Item>
                                                <div className='menu-inner'>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to="ecommerce"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="E-commerce" />E-commerce
                                                <span>Online business solutions.</span>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to="high-risk"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Partners" />High-Risk
                                                <span>Secure transactions for all.</span>
                                                </NavDropdown.Item>
                                                </div>
                                                </div>
                                                </Row>
                                            </div>
                                            <div  className='megamenubg spchgt'>
                                                <div className='menutitle yelw'>CORPORATE CARDS</div>
                                                    <NavDropdown.Item as={Link} onClick={closeMenu} to='issuing'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon3.svg`} alt="Pay Issuing" />TCB Pay Issuing
                                                    <span>Credit cards with prepaid and postpaid capabilities.</span>
                                                    </NavDropdown.Item>
                                                    <div className='menu-inner'>
                                                    <NavDropdown.Item as={Link} onClick={closeMenu} to='a-n'><img src={`${config.siteBaseUrl}/assets/imgs/m-arw1.svg`} alt="Alert" />Alert & Notifications
                                                    <span>Real-time alerts.</span>
                                                    </NavDropdown.Item>
                                                    </div>         
                                            </div>
                                            <div  className='megamenubg techmu'>
                                                <div className='menutitle'>TECH</div>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to='white-label'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon22.svg`} alt="White-Label" />TCB Pay White-Label
                                                <span>Customizable payment system for businesses.</span>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to='integrations'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon20.svg`} alt="Integrations" />Integrations
                                                <span>Connects your payment system with other platforms.</span>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to='it-solutions' className='itmb-20'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon21.svg`} alt="it-solutions" />IT Solutions
                                                <span>Tech services for smooth operations and security.</span>
                                                </NavDropdown.Item>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </Drawer>

                                <Drawer
                                    open={drawerStates.isOpen2}
                                    direction='right'
                                    enableOverlay={false}
                                >
                                    
                                <div className='navbar mb'>
                                    <div className='navbar-nav'>
                                            <Offcanvas.Header closeButton>
                                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                            <Navbar.Brand href="/tcbpay-website"><img src={`${config.siteBaseUrl}/assets/imgs/header-logo1.svg`} alt="Header Logo" /></Navbar.Brand>
                                            </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <button onClick={() => toggleDrawer('isOpen2')} className='back-mbbtn'><img src={`${config.siteBaseUrl}/assets/imgs/lft-arwmb.png`} alt='' />Back</button>
                                            <div className='megamenubg'>
                                                <div className='menutitle'>ABOUT TCB PAY</div>
                                                <Row>
                                                <div className='col-md-12'>
                                                    <NavDropdown.Item as={Link} onClick={closeMenu} to="about-us"><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon10.svg`} alt="About Us" />About Us
                                                    <span>Overview of our company values.</span>
                                                    </NavDropdown.Item>
                                                    <div className='menu-inner'>
                                                    <NavDropdown.Item as={Link} onClick={closeMenu} to="our-team"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Team" />Team
                                                    <span>Meet our dedicated team.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} onClick={closeMenu} to="partners"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Partners" />Partners
                                                    <span>Collaborate with us.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} onClick={closeMenu} to="careers"><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Careers" />Careers
                                                    <span>Explore job opportunities.</span>
                                                    </NavDropdown.Item>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 spchgt1'>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to='pricing'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon14.svg`} alt="Pricing" />Pricing
                                                <span>Review available service pricing plans.</span>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to={config.blogUrl} target='blank'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon11.svg`} alt="Blog" />Blog
                                                <span>Explore industry insights and updates.</span>
                                                </NavDropdown.Item>
                                                </div>
                                                </Row>
                                            </div>
                                            <div className='megamenubg spchgt'>
                                                <div className='menutitle yelw'>TECH</div>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to='security' className='tch2'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon15.svg`} alt="Menu Icon 14" />Security
                                                <span>Learn about our security measures.</span>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item as={Link} onClick={closeMenu} to='system-status' className='tch2'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon17.svg`} alt="Menu Icon 17" />System Status
                                                <span>Check system performance and uptime.</span>
                                                </NavDropdown.Item>
                                            </div>
                                            <div  className='megamenubg'>
                                                <div className='menutitle'>GET HELP</div>
                                                    <NavDropdown.Item as={Link} onClick={closeMenu} to='contact'><img src={`${config.siteBaseUrl}/assets/imgs/menu-icon18.svg`} alt="Contact" />Contact Us
                                                    <span>Reach out for support or inquiries.</span>
                                                    </NavDropdown.Item>
                                                    <div className='menu-inner'>
                                                    <NavDropdown.Item as={Link} onClick={closeMenu} to='faq'><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="FAQ" />FAQ
                                                    <span>Questions and answers.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} onClick={closeMenu} to='wiki-portal'><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Portal Wiki" />TCB Pay Portal Wiki
                                                    <span>A step-by-step guide.</span>
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} onClick={closeMenu} to='wiki-issuing'><img src={`${config.siteBaseUrl}/assets/imgs/m-arw.svg`} alt="Pay Issuing" />TCB Pay Issuing Wiki
                                                    <span>Understand the platform.</span>
                                                    </NavDropdown.Item>
                                                    </div>    
                                            </div>
                                    </div>
                                </div>

                                </Drawer>

                                <Drawer
                                    open={drawerStates.isOpen3}
                                    direction='right'
                                    enableOverlay={false}
                                >
                                    
                                    <div className='navbar mb' style={{display:'inline-block'}}>
                                    <div className='navbar-nav'>
                                            <Offcanvas.Header closeButton>
                                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                            <Navbar.Brand href="/tcbpay-website"><img src={`${config.siteBaseUrl}/assets/imgs/header-logo1.svg`} alt="Header Logo" /></Navbar.Brand>
                                            </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <button onClick={() => toggleDrawer('isOpen3')} className='back-mbbtn'><img src={`${config.siteBaseUrl}/assets/imgs/lft-arwmb.png`} alt='' />Back</button>
                                            
                                            <div className='mobile-menuoffcanvas1'>
                                                <ul>
                                                    <li><Link to="https://portal.tcbpay.com/" target='blank'><img src={`${config.siteBaseUrl}/assets/imgs/sm-icon.svg`} alt="" /> Sign in to TCB Pay <span>Portal</span></Link></li>
                                                    <li><Link to="https://issuing.tcbpay.com/" target='blank'><img src={`${config.siteBaseUrl}/assets/imgs/sm-icon1.svg`} alt="" /> Sign in to TCB Pay <span>Issuing</span></Link></li>
                                                </ul> 
                                            </div>
                                    </div>
                                </div>

                                </Drawer>

                                    <Nav.Link href={`${config.siteBaseUrl}#show-calendly`} className='fxdbtn-mb'>
                                    <div className='integration-btn'>Book your free consultation <FontAwesomeIcon icon={faArrowRight} /></div> </Nav.Link>

                                    </Nav>
       
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    );
}

export default Header;
