import React,{useState,useEffect} from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import config from '../Config';
import { SystemStatusService } from '../services/SystemStatusService';
import classNames from 'classnames';
import moment from 'moment';


interface IState {
    loading: boolean;
    systemStatus: any;
    errorMsg: string;
  }

export const SystemStatusSec = () => {
    
    const [state, setState] = useState<IState>({
        loading: false,
        systemStatus: [] as any,
        errorMsg: "",
    });

    //network request
    useEffect(() => {
        setState({ ...state, loading: true });
        SystemStatusService.getAllSystemStatus()
        .then((res) =>
        setState({
            ...state,
            loading: false,
            systemStatus: res.data as any,
        })
        )
        .catch((err) =>
        setState({
            ...state,
            loading: false,
            errorMsg: err.message,
        })
        );
    //eslint-disable-next-line
    }, []);
    const { loading, systemStatus, errorMsg } = state;
    const system_status = systemStatus.response?.system_status;
    const past_incidents = systemStatus.response?.past_incidents;
   
    const past_incidents_length = past_incidents ? Object.keys(past_incidents).length : 0;

  return (
    <>
    <section className='banner-bg bg-transparentcustm systmbg'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' className='bghgt'/>
      
        <Container>
           <Row>
            <div className='text-left col-md-12'>
            <span></span>
            <h2>System status</h2>
            </div>
            <div className='text-left col-md-5'>
            <p>If you are experiencing an issue not listed below,<br /> please contact us.</p>
            <Link className="contact-btn1 mt-10" to="/contact">Contact Us</Link>
            </div>
          </Row>  
        </Container>
    </section>

    <section className='system-statusbx'>
        <div className="container">
            <div className='row system-accodianbx'>
                <div className='col-lg-6'>
                    <Accordion defaultActiveKey="0">

                    {system_status?.length > 0 &&
                    system_status?.map((sysStatus: any,sln: number) => (
                        sln < 4 &&
                        <Accordion.Item eventKey={sysStatus.parameters_id}>
                            <Accordion.Header className={sysStatus.in_maintainance>0?`inblue`:sysStatus.current_status>0?`active`:`inactive`}>{sysStatus.parameters_name}</Accordion.Header>
                            <Accordion.Body dangerouslySetInnerHTML={{__html: sysStatus.status_note}}></Accordion.Body>
                        </Accordion.Item>
                    ))}

                    </Accordion>
                </div>
                <div className='col-lg-6'>
                    <Accordion defaultActiveKey="4">

                    {system_status?.length > 0 &&
                    system_status?.map((sysStatus: any,sln: number) => (
                        sln >= 4 &&
                        <Accordion.Item eventKey={sysStatus.parameters_id}>
                            <Accordion.Header className={sysStatus.in_maintainance>0?`inblue`:sysStatus.current_status>0?`active`:`inactive`}>{sysStatus.parameters_name}</Accordion.Header>
                            <Accordion.Body dangerouslySetInnerHTML={{__html: sysStatus.status_note}}></Accordion.Body>
                        </Accordion.Item>
                    ))}
                    
                    </Accordion>
                </div>
            </div>

            {past_incidents_length > 0 &&
            <div className='row'>
                <div className="col-lg-12">
                <div className="past-incidents-block">
                    <div className="incidents-list format-expanded">
                        <h3>Past Incidents</h3>

                        {Object.entries(past_incidents)?.map(([pastKey, pastVal]: [string, any]) => (
                            Object.keys(pastVal).length > 0 || pastVal.length > 0 
                            ?
                            <>
                                <div className="status-day font-regular">
                                    <div className="date border-color ">{moment(pastKey).format('ll')}</div>

                                    {Object.entries(pastVal)?.map(([pastKey2, pastVal2]: [string, any]) => (
                                        <>
                                        <div className="incident-container">
                                            <div className="incident-title impact-none ">
                                                <p>{pastVal2.parameters_name}</p>
                                            </div>

                                            {Object.entries(pastVal2.action)?.map(([pastKey3, pastVal3]: [string, any]) => (
                                                <> 
                                                <div className="updates-container">
                                                    <div className={classNames('update font-regular', pastVal3.class_color)}>
                                                        <h5> <span className={classNames('incident_status', pastVal3.status_color)}>{pastVal3.incident_status}</span> - <span className="incident_time"> {pastVal3.action_time} EST </span> </h5>
                                                        <p className="whitespace-pre-wrap">{pastVal3.action_note}</p>
                                                    </div>
                                                </div>
                                                </> 
                                            ))}
                                        </div>
                                        </>
                                    ))}
                                </div>
                            </>
                            :
                            <>
                                <div className="status-day font-regular no-incidents">
                                    <div className="date border-color ">{moment(pastKey).format('ll')}</div>
                                    <p className="color-secondary no-list-msg">No incidents reported today.</p>
                                </div>
                            </>
                        ))}

                    </div>

                    <div className="incidents-action mt-20">
                        <Link to="/all-incidents" className="chat-btn1">All incidents <FontAwesomeIcon icon={faArrowRight} /></Link>
                    </div>
                </div>
                </div>
            </div>}


            </div>
    </section>

    </>
  )
}
