import React,{useState} from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { Button, Modal } from "react-bootstrap";


export const IntrigrationSec = () => {

  const [showModal, setShowModal] = useState<{ [key: string]: boolean }>({});
  const [expandScreen, setExpandScreen] = useState<any>(true);

  const handleShow = (modalId: string) => {
    setShowModal((prevState) => ({ ...prevState, [modalId]: true }));
  };

  const handleClose = (modalId: string) => {
    setShowModal((prevState) => ({ ...prevState, [modalId]: false }));
  };

  const exampleCodeText_1 = `<form name="" id="formID" action="${config.gatewayApisUrlProd}/apis/get-card-details.php" method="post">
  <input type="text" name="username" value=" tcbpayusername"/>
  <input type="text" name="api_key" value=" tcbpayapikey"/>
  <input type="text" name="token" value="XXXXXXXXXXXXXXXXXXXXXXXXX"/>
  <br/>
  <input type="submit" value="submit"/>
</form>`;
  const exampleCodeText_2 = `{ "response": { "code": "00", "description": "success", "details": { "token": "XXXXXXXXXXXXXXXXXXXXXXXXX", "card_holder_name": "Test card holder name", "creditcard_number": "400551******0013", "expiry_month": "06", "expiry_year": "2025" } } }`;

  const onCopy = React.useCallback((id:number) => {
    const element = document.getElementById("myTooltip"+id);
    if (element) {
      element.classList.add("show");
      const intervalId = setInterval(() => {
        element.classList.remove("show");
        clearInterval(intervalId);
      }, 2000);
    }
  }, []);

  return (
    <>
    <section className="credit-api-main">
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <IntrigrationLeftSec />
                </div>
                <div className="col-lg-9">

                    <div className="creadit-api-upper-wrapper">
                          <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                        <h1>Vault API</h1>

                        <ul className="card-url">
                            <li>
                                <div className="card-url-list">
                                     <p>Sandbox endpoint URL:</p>
                                     <span><Link to=''>{`${config.gatewayApisUrlSand}/apis/{method}`}</Link></span>
                                </div>
                              
                            </li>
                            
                            <li>
                                <div className="card-url-list">
                                    <p>Example:</p>
                                    <span><Link to=''>{config.gatewayApisUrlSand}/apis/get-card-details.php</Link></span>
                                </div>
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Production endpoint URL:</p>
                                    <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="creadit-api-lower-wrapper">
                        <div className="api-menu-item-title">
                            <h2>Card Details</h2>
                        </div>
                        <div className="api-menu-item-wrapper">
                            <h3>Card Details API</h3>
                            <hr />
                            <p>This API is responsible to retrieve card details from Vault.</p>
                            <ul className="card-url api-menu-url pb-40">
                                <li>
                                    <div className="card-url-list">
                                         <p>URL to call</p>
                                         <span><Link to=''>{config.gatewayApisUrlSand}/apis/get-card-details.php</Link></span>
                                    </div>
                                  
                                </li>
                                <li>
                                    <div className="card-url-list">
                                        <p>Method of form submission</p>
                                        <span><Link to=''>POST</Link></span>
                                   </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>

                    <div className="api-table">
                        <h5>This table describes the request parameters for Card Details API. </h5>

                                <div className="table-responsive">
                                  <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <th>Variable Name</th>
                                        <th>Description</th>
                                        <th>Required</th>
                                        <th>Expected Values</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>username</td>
                                        <td>TCB Pay Gateway Username</td>
                                        <td>Y</td>
                                        <td>TCB Pay Gateway Username</td>
                                      </tr>
                                      <tr>
                                        <td>api_key</td>
                                        <td>TCB Pay Gateway API Key</td>
                                        <td>Y</td>
                                        <td>TCB Pay Gateway API Key</td>
                                      </tr>
                                      <tr>
                                        <td>token</td>
                                        <td>Unique token number, get it during creating a new card vault</td>
                                        <td>Y</td>
                                        <td>XXXXXXXXXXXXXXXXXXXXXXXXX</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                        <div className="example-box-wrapper">
                            <div className="d-flex align-items-start">
                                <b></b>
                               <p><span>Example</span></p>
                            </div>
                            <div className="example-box">
                                <div className="example-box-top">
                                <div className="tooltip1" id="myTooltip1">
                                  <span className="tooltiptext">Copied</span>
                                </div>
                                <CopyToClipboard onCopy={() => onCopy(1)} text={exampleCodeText_1}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                                <FontAwesomeIcon key={1} onClick={() => handleShow("expand1")} icon={faUpRightAndDownLeftFromCenter} />
                                </div>
                                <div className="ex-text-box">
                                    <pre>{exampleCodeText_1}</pre>
                                </div>
                            </div>

                            <Modal show={showModal["expand1"]}  size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => handleClose("expand1")}>
                                <CopyToClipboard onCopy={() => onCopy(1)} text={exampleCodeText_1}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                              <Modal.Header closeButton>
                                <Modal.Title> </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <pre>{exampleCodeText_1}</pre>
                              </Modal.Body>
                            </Modal>

                        </div>
                    </div>

                    
                        <div className="api-menu-item-wrapper mt-60">
                            <h3>Response</h3>
                            <hr />
                            <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, three elements are returned, code, description details. The response code is always 00 for a successful API call.</p>
                            <div className="example-box-wrapper mb-20">
                                <div className="d-flex align-items-start">
                                    <b></b>
                                    <span>Sample Response</span>
                                </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                    <div className="tooltip1" id="myTooltip2">
                                      <span className="tooltiptext">Copied</span>
                                    </div>
                                    <CopyToClipboard onCopy={() => onCopy(2)} text={exampleCodeText_2}>
                                      <FontAwesomeIcon icon={faCopy} />
                                    </CopyToClipboard>
                                    <FontAwesomeIcon key={2} onClick={() => handleShow("expand2")} icon={faUpRightAndDownLeftFromCenter} />
                                    </div>
                                    <div className="ex-text-box">
                                        <pre>{exampleCodeText_2}</pre>
                                    </div>

                                    <Modal show={showModal["expand2"]} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => handleClose("expand2")}>
                                        
                                      <Modal.Header closeButton>
                                        <CopyToClipboard onCopy={() => onCopy(2)} text={exampleCodeText_2}>
                                          <FontAwesomeIcon icon={faCopy} />
                                        </CopyToClipboard>
                                        <Modal.Title> </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <pre>{exampleCodeText_2}</pre>
                                      </Modal.Body>
                                    </Modal>

                                </div>
                            </div>

                        </div>
                 
    

                </div>
            </div>
        </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />

        <ScrollToTop smooth />
    </section>
    </>
  )
}



