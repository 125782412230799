import React,{useState,useEffect} from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import config from '../Config';
import { SystemStatusDetailsService } from '../services/SystemStatusDetailsService';
import classNames from 'classnames';
import moment from 'moment';

interface IState {
    loading: boolean;
    systemStatus: any;
    errorMsg: string;
  }

export const SystemDetailSec = () => {

    const [state, setState] = useState<IState>({
        loading: true,
        systemStatus: [] as any,
        errorMsg: "",
    });

    const [startDate, setStartDate] = useState<string | null>(moment().format('YYYY-MM'));

    const handleSetDate = (dateStr:string) => {
        SystemStatusDetailsService.getAllSystemStatusDetails(dateStr)
        .then((res) =>{
                setState({
                    ...state,
                    loading: false,
                    systemStatus: res.data as any,
                });             
            }
        )
        .catch((err) =>{
                setState({
                    ...state,
                    loading: false,
                    errorMsg: err.message,
                });
            }
        );
    }

    //network request
    useEffect(() => {
        SystemStatusDetailsService.getAllSystemStatusDetails(startDate)
        .then((res) =>{
                setState({
                    ...state,
                    loading: false,
                    systemStatus: res.data as any,
                });             
            }
        )
        .catch((err) =>{
                setState({
                    ...state,
                    loading: false,
                    errorMsg: err.message,
                });
            }
        );
    }, []);


    const { loading, systemStatus, errorMsg } = state;

    const lastDate = systemStatus.response?.last;
    const all_incidents = systemStatus.response?.all_incidents;
    const all_incidents_length = all_incidents ? Object.keys(all_incidents).length : 0;

  return (
    <>
    <section className='banner-bg bg-transparentcustm' style={{minHeight:'auto'}}>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' className='bghgt'/>
      
        <Container>
           <Row>
            <Col xs lg="12" className='text-left'>
            <span></span>
            <Link to="/system-status" className='apidetail-btn mb-10'><FontAwesomeIcon icon={faArrowRight} /> Return to real-time status</Link>
            <h2 className='mb-systxt'>Incident History</h2>
            </Col>
          </Row>  
        </Container>
    </section>

    <section className='system-statusbx'>
        <div className="container">
            <div className='row'>

                <div className='col-lg-12'>
                    <div className="history-backpage">
                        <div className="history-header">
                            <div className="pagination-container">
                                <div className="pagination">
                                    <Link to="" onClick={() => handleSetDate(moment(lastDate).subtract(3, 'month').format('YYYY-MM'))} className="previous-page border-color color-secondary" aria-label="Previous page"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                    <span className="current">
                                        <span>{moment(lastDate).subtract(2, 'month').format('MMMM YYYY')}</span>
                                        &nbsp; - &nbsp;
                                        <span>{moment(lastDate).format('MMMM YYYY')} </span>
                                    </span>
                                    <Link to="" onClick={() => handleSetDate(moment(lastDate).add(3, 'month').format('YYYY-MM'))} className="next-page border-color color-secondary" aria-label="Next page"><FontAwesomeIcon icon={faChevronRight}  /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="months-container">
                        {all_incidents_length > 0 &&  
                            Object.entries(all_incidents)?.map(([pastKey, pastVal]: [string, any]) => (
                            Object.keys(pastVal).length > 0 || pastVal.length > 0 
                            ? 
                            <>
                            <div className="month">
                                <h4 className="month-title font-largest border-color">{moment(pastKey).format('MMMM YYYY')}</h4>
                                <div className="month-content">
                                    <div className="incident-history">
                                        <div className="incident-list">
                                        {Object.entries(pastVal)?.map(([pastKey2, pastVal2]: [string, any]) => (
                                            <>
                                            <div className="incident-data ">
                                                <div className="incident-date">{moment(pastKey2).format('ll')}</div>

                                                {pastVal2?.map((pastKey3:any) => (
                                                    <>
                                                    <div className="incident-container">
                                                        <div className="incident-list-wrap">
                                                            <h6>{pastKey3.parameters_name}</h6>

                                                            {Object.entries(pastKey3.action)?.map(([pastKey4, pastVal4]: [string, any]) => (
                                                                <>
                                                                <div className={classNames('incident-action-row', pastVal4.class_color)}>

                                                                    <div className="secondary font-small color-secondary">
                                                                        <h5> <span className={classNames('incident_status', pastVal4.status_color)}>{pastVal4.incident_status}</span> - <span
                                                                                className="incident_time"> {pastVal4.action_time} EST </span> </h5>

                                                                    </div>
                                                                    <div className="message incident-body color-primary">{pastVal4.action_note}</div>
                                                                </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    </>
                                                ))}

                                            </div>
                                            </>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </>
                            :
                            <>
                            <div className="month">
                                <h4 className="month-title font-largest border-color">{moment(pastKey).format('MMMM YYYY')}</h4>
                                <div className="month-content">
                                    <div className="incident-history">
                                        <div className="incident-list">
                                            <span className="small no-list-msg">No incidents reported for this month.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </> 
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    </section>
    </>
  )
}
