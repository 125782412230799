import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link } from 'react-router-dom';
import config from '../Config';

export const IntregrationList = () => {
  return (
    <>
    <section className='security-bx'>
    {/* <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg1`} alt="" className="blue-dots2" /> */}
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='security-list intrelist'>
                    <div className="pp-contenttitle"> APIs</div>
                        <ul>
                            <li className='d-flex'>
                              <Link to='/integrations/credit-card-API/sale'>
                              <div className='security-list-lft'><img src={`${config.siteBaseUrl}/assets/imgs/in-icon1.svg`} alt='' style={{width:'35px'}} /> <span>Credit Card</span></div>
                              <div className='security-list-rgt'><p>Experience seamless credit card transactions with our robust Credit Card API. Designed for developers and businesses, our API provides an easy-to-integrate solution that supports secure processing, authorization, and handling of credit card payments. Enhance your customers’ checkout experience with our reliable and flexible payment interface.</p></div>
                              <div className='arw'><svg xmlns="http://www.w3.org/2000/svg" width="12.438" height="15.459" viewBox="0 0 12.438 15.459">
  <path id="Path_76628" data-name="Path 76628" d="M7.845,2.439a2,2,0,0,1,3.31,0l5.726,8.438A2,2,0,0,1,15.226,14H3.774a2,2,0,0,1-1.655-3.123Z" transform="translate(14 -1.771) rotate(90)" fill="#A0AFBD"/>
</svg></div>
                              </Link>

                            </li>
                            <li className='d-flex'>
                              <Link to='/integrations/vault-API/add-card'>
                              <div className='security-list-lft'><img src={`${config.siteBaseUrl}/assets/imgs/in-icon2.svg`} alt='' /> <span>Vault</span></div>
                              <div className='security-list-rgt'><p>Securely store and protect sensitive card information with our Vault service. Using advanced encryption, our Vault ensures that customer data is kept safe, helping you maintain PCI compliance with ease. Trust in our Vault to minimize risk and build confidence with your customers through enhanced data security.</p></div>
                              <div className='arw'><svg xmlns="http://www.w3.org/2000/svg" width="12.438" height="15.459" viewBox="0 0 12.438 15.459">
  <path id="Path_76628" data-name="Path 76628" d="M7.845,2.439a2,2,0,0,1,3.31,0l5.726,8.438A2,2,0,0,1,15.226,14H3.774a2,2,0,0,1-1.655-3.123Z" transform="translate(14 -1.771) rotate(90)" fill="#A0AFBD"/>
</svg></div>
                              </Link>
                              
                            </li>
                            <li className='d-flex'>
                              <Link to='/integrations/subscription-API/add-plan'>
                              <div className='security-list-lft'><img src={`${config.siteBaseUrl}/assets/imgs/in-icon3.svg`} alt='' /> <span>Subscriptions</span></div>
                              <div className='security-list-rgt'><p>Drive your business growth with our Subscriptions service, tailored for recurring revenue models. Our system simplifies the management of subscription payments, providing a stable and predictable cash flow. With automated billing cycles and customizable plans, you can focus on providing value to your customers while we handle the rest.</p></div>
                              <div className='arw'><svg xmlns="http://www.w3.org/2000/svg" width="12.438" height="15.459" viewBox="0 0 12.438 15.459">
  <path id="Path_76628" data-name="Path 76628" d="M7.845,2.439a2,2,0,0,1,3.31,0l5.726,8.438A2,2,0,0,1,15.226,14H3.774a2,2,0,0,1-1.655-3.123Z" transform="translate(14 -1.771) rotate(90)" fill="#A0AFBD"/>
</svg></div>
                              </Link>
                              
                            </li>
                            <li className='d-flex'>
                              <Link to='/integrations/bank-verification-API/bank-verification'>
                              <div className='security-list-lft'><img src={`${config.siteBaseUrl}/assets/imgs/in-icon7.svg`} alt='' /> <span>Bank verification</span></div>
                              <div className='security-list-rgt'><p>Ensure secure and accurate transactions with our Bank Verification feature. This service confirms the validity of a customer’s bank account details before processing payments, reducing the risk of errors and fraud. Streamline your onboarding process and build trust with verified account information for a smoother, more reliable payment experience.</p></div>
                              <div className='arw'><svg xmlns="http://www.w3.org/2000/svg" width="12.438" height="15.459" viewBox="0 0 12.438 15.459">
  <path id="Path_76628" data-name="Path 76628" d="M7.845,2.439a2,2,0,0,1,3.31,0l5.726,8.438A2,2,0,0,1,15.226,14H3.774a2,2,0,0,1-1.655-3.123Z" transform="translate(14 -1.771) rotate(90)" fill="#A0AFBD"/>
</svg></div>
                              </Link>
                              
                            </li>
                            <li className='d-flex'>
                              <Link to=''>
                              <div className='security-list-lft'><img src={`${config.siteBaseUrl}/assets/imgs/in-icon4.svg`} alt='' /> <span>ACH</span></div>
                              <div className='security-list-rgt'><p>Optimize your payment processes with our ACH payment solutions. Ideal for direct bank transfers, our ACH service is perfect for handling bulk payments, payroll, and B2B transactions. Benefit from lower processing fees, reliable transfers, and the convenience of electronic payments with our streamlined ACH services.</p></div>
                              <div className='arw'><svg xmlns="http://www.w3.org/2000/svg" width="12.438" height="15.459" viewBox="0 0 12.438 15.459">
  <path id="Path_76628" data-name="Path 76628" d="M7.845,2.439a2,2,0,0,1,3.31,0l5.726,8.438A2,2,0,0,1,15.226,14H3.774a2,2,0,0,1-1.655-3.123Z" transform="translate(14 -1.771) rotate(90)" fill="#A0AFBD"/>
</svg></div>
                              </Link>
                              
                            </li>
                            {/* <li className='d-flex'>
                              <Link to=''>
                              <div className='security-list-lft'><img src='assets/imgs/in-icon5.svg' alt='' style={{width:'35px'}}/> <span>Merchant Onboarding</span></div>
                              <div className='security-list-rgt'><p>Kickstart your merchant services with ease using our Merchant Onboarding platform. Our comprehensive onboarding process is designed to get your payment systems up and running quickly, with support and guidance at every step. From setting up accounts to integrating payment gateways, we make the journey smooth and hassle-free.</p></div>
                              <div className='arw'><svg xmlns="http://www.w3.org/2000/svg" width="12.438" height="15.459" viewBox="0 0 12.438 15.459">
  <path id="Path_76628" data-name="Path 76628" d="M7.845,2.439a2,2,0,0,1,3.31,0l5.726,8.438A2,2,0,0,1,15.226,14H3.774a2,2,0,0,1-1.655-3.123Z" transform="translate(14 -1.771) rotate(90)" fill="#A0AFBD"/>
</svg></div>
                              </Link>
                              
                            </li>
                            <li className='d-flex'>
                              <Link to=''>
                              <div className='security-list-lft'><img src='assets/imgs/in-icon6.svg' alt='' /> <span>ANI</span></div>
                              <div className='security-list-rgt'><p>Visa Account Name Inquiry (ANI) helps Visa partners, such as online merchants, verify that a cardholder’s name matches the name held by their issuing bank. This adds an extra security check during card onboarding and pre-transaction processes, reducing the risk of fraud in Card Not Present (CNP) transactions and push/pull payments.</p></div>
                              <div className='arw'><svg xmlns="http://www.w3.org/2000/svg" width="12.438" height="15.459" viewBox="0 0 12.438 15.459">
  <path id="Path_76628" data-name="Path 76628" d="M7.845,2.439a2,2,0,0,1,3.31,0l5.726,8.438A2,2,0,0,1,15.226,14H3.774a2,2,0,0,1-1.655-3.123Z" transform="translate(14 -1.771) rotate(90)" fill="#A0AFBD"/>
</svg></div>
                              </Link>
                              
                            </li>                             */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="our-integration-inner"> 
                        <h6 className="text-center">Our integrations</h6>
                        <div className='integrations-logo'>
                            <ul>
                                <li><Link to='/integrations/shopify'><img src={`${config.siteBaseUrl}/assets/imgs/our-logo1.svg`} alt="" /></Link></li>
                                <li><Link to='/integrations/PrestaShop'><img src={`${config.siteBaseUrl}/assets/imgs/our-logo2.svg`} alt="" /></Link></li>
                                <li><Link to='/integrations/OpenCart'><img src={`${config.siteBaseUrl}/assets/imgs/our-logo3.svg`} alt="" /></Link></li>
                                <li><Link to='/integrations/WooCommerce'><img src={`${config.siteBaseUrl}/assets/imgs/our-logo4.svg`} alt="" /></Link></li>
                                <li><Link to='/integrations/magento'><img src={`${config.siteBaseUrl}/assets/imgs/our-logo5.svg`} alt="" /></Link></li>
                                <li><Link to='/integrations/XCart'><img src={`${config.siteBaseUrl}/assets/imgs/our-logo6.svg`} alt="" /></Link></li>
                                <li><Link to='/integrations/osCommerce'><img src={`${config.siteBaseUrl}/assets/imgs/our-logo7.svg`} alt="" /></Link></li>
                                <li><Link to='/integrations/ZenCart'><img src={`${config.siteBaseUrl}/assets/imgs/our-logo8.svg`} alt="" /></Link></li>
                                <li><Link to='/integrations/konnektive-crm'><img src={`${config.siteBaseUrl}/assets/imgs/our-logo9.svg`} alt="" /></Link></li>
                                <li><Link to='/integrations/sticky'><img src={`${config.siteBaseUrl}/assets/imgs/our-logo10.svg`} alt="" /></Link></li>
                                <li><Link to=''><img src={`${config.siteBaseUrl}/assets/imgs/our-logo11.svg`} alt="" /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`} alt="" className="orng-dots" />
    </section>
    </>
  )
}
