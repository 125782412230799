import React,{useState,useEffect} from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';

import { IntegrantionModuleService } from '../services/IntegrantionModuleService';

interface IState {
    loading: boolean;
    module: any;
    errorMsg: string;
  }

export const IntegrationsOpenCartSec = () => {

    const [state, setState] = useState<IState>({
        loading: false,
        module: [] as any,
        errorMsg: "",
    });

    //network request
    useEffect(() => {
        setState({ ...state, loading: true });
        IntegrantionModuleService.getModuleDetails('OpenCart')
        .then((res) =>{
        setState({
            ...state,
            loading: false,
            module: res.data as any,
        })

        }
        
        )
        .catch((err) =>{
        setState({
            ...state,
            loading: false,
            errorMsg: err.message,
        })

        }
        );
    //eslint-disable-next-line
    }, []);


    const { loading, module, errorMsg } = state;
    const modDetails = module.response?.module_details;
    const latestVersion = module.response?.module_details.length > 0 ? module.response.module_details[0] : [];
    const contentDetArr = module.response?.module_details.length > 0 ? module.response.module_details[0].jsoncontent : [];

    return (
        <>
            <section className="credit-api-main">
                <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <IntrigrationLeftSec />
                        </div>
                        <div className="col-lg-9">
                            <div className="creadit-api-upper-wrapper">
                                <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                                <h1>OpenCart</h1>

                                <ul className="card-url">
                                    {Object.entries(contentDetArr).map(([keyName, keyValue]: [any, any]) => (
                                        <>
                                        <li>
                                            <div className="card-url-list">
                                                <p>{keyName}:</p>
                                                <span>{keyValue}</span>
                                            </div>
                                        </li>
                                        </>
                                    ))}
                                </ul>
                                <p className='mt-30'>This plugin allows you to use the TCB Pay payment portal for your Opencart, as an additional payment option.</p>
                            </div>

                            <div className="creadit-api-lower-wrapper">
                                <div className="api-menu-item-wrapper">
                                    <h3>Installation</h3>
                                    <hr />
                                    <Link to={`${config.pluginModuleUrl}/${latestVersion.download_url}/${latestVersion.file_name}`} target='_blank' className="integration-btn1 mb-20">Download the Opencart extension package <FontAwesomeIcon icon={faArrowRight} /></Link>
                                    {modDetails?.length > 1 &&
                                        <>
                                            <p>Download older versions of the package:</p>
                                            <div className="listintri">
                                                <ul>
                                                {modDetails?.map((keyName: any) => (
                                                    <>
                                                        <li>Download <Link to={`${config.pluginModuleUrl}/${keyName.download_url}/${keyName.file_name}`} target='_blank'>{keyName.version_name}</Link> 
                                                        {keyName.dependency ? `for ${keyName.dependency}` : ""}</li>
                                                    </>
                                                    ))
                                                }
                                                </ul>
                                            </div>
                                        
                                        </>
                                    }

                                </div>
                            </div>

                            <div className="example-box-wrapper mt-30 mb-30">
                                <div className="d-flex align-items-start">
                                    <p>There are two ways to install the plugin.</p>
                                </div>
                                <div className="d-flex align-items-start">
                                    <b>1 </b>
                                    <p>Unzip the opencart plug-in folder there will be 3 folders admin, catalog, and the system then upload 3 folders inside the OpenCart project folder.</p>
                                </div>
                            </div>

                            <div className="example-box-wrapper mb-30">
                                <div className="d-flex align-items-start">
                                    <b>2 </b>
                                    <p>Login into the OpenCart admin module then go to the Extension → Installer option → select the plug-in zip and upload it then go to the Extensions option and select a payment option from the extension type we will find TCB Pay and then install by clicking the install button.</p>
                                </div>
                            </div>
                            <img src={`${config.siteBaseUrl}/assets/imgs/SS!.png`} alt='' />

                            <div className="api-menu-item-wrapper mt-60">
                                <h3>Manage Plugin</h3>
                                <hr />
                                <p>After successful installation of the plugin, you have to enable and manage to set the TCB Pay payment gateway from <br/>Extensions → Extensions → Payment Methods → TCBPay Payment Gateway → Click on Edit.</p>
                                <p>In the manage screen, you can set the following values</p>
                                <div className='listintri'>
                                    <ul>
                                        <li><strong>Merchant Username:</strong> This is the username provided by TCB Pay when you signed up for an account.</li>
                                        <li><strong>API Key:</strong> This is the API Key provided by TCB Pay when you signed up for an account.</li>
                                        <li><strong>Credit Card Types:</strong> Select your accepted card.</li>
                                        <li><strong>Test Mode:</strong> If we select yes then we get sandbox if not then production.</li>
                                        <li><strong>Status:</strong> We must set it to enable.</li>
                                    </ul>
                                </div>
                                <img src={`${config.siteBaseUrl}/assets/imgs/SS2.png`} alt='' />
                            </div>


                        </div>
                    </div>
                </div>
                <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`} alt="" className="orng-dots" />

                <ScrollToTop smooth />
            </section>
        </>
    )
}
