import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = () => {
  return (
    <>
    <section className='banner-bg bg-transparentcustm'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <div className='text-left col-md-12'>
            <span><img src='assets/imgs/abut-icon.svg' alt='' />ABOUT US</span>
            <h2>Shaping the future of payments together</h2>
            </div>
            <div className='text-left col-md-6'>
            <p>At TCB Pay, we are at the forefront of fintech innovation, specializing in payment processing solutions and corporate card issuing. </p>
            <p>Our team is dedicated to empowering businesses with secure, efficient, and customizable financial tools. Get to know the visionaries behind our company.</p>
            </div>
          </Row>    
        </Container>

    </section>
    </>
  )
}
