  
  const domainName:string = window.location.hostname;
  let siteBaseUrl;

  if(domainName==='localhost'){
    siteBaseUrl = 'http://localhost:3000';
  } else {
    siteBaseUrl = 'https://stg.tcbpay.com';
    //siteBaseUrl = 'https://dev.tcbpay.com/tcbpay-website';
  }

  const config = {
    internalApiUrl: 'https://stg.tcbpay.com/internal_apis',
    pluginModuleUrl: 'https://stg.tcbpay.com',
    gatewayApisUrlSand: 'https://gateway2-stg.tcbpay.com',
    gatewayApisUrlProd: 'https://portal.tcbpay.com',
    siteBaseUrl: siteBaseUrl,
    blogUrl: 'https://www.tcbpay.com/blog',
    gCaptchaSiteKey: '6LcekHAqAAAAAPqhuZVPfqFzT-IwVDp8iUPLZ8k8',
    gCaptchaSecretKey: '6LcekHAqAAAAAMxkXUTvtuj8h_JoEBkk84kv-c5a'
  };

  // if(domainName==='localhost'){
  //   siteBaseUrl = 'http://localhost:3000';
  // } else {
  //   siteBaseUrl = 'https://dev.tcbpay.com/tcbpay-website';
  // }

  // const config = {
  //   internalApiUrl: 'https://dev.tcbpay.com/internal_apis',
  //   pluginModuleUrl: 'https://dev.tcbpay.com',
  //   gatewayApisUrlSand: 'https://gateway2-stg.tcbpay.com',
  //   gatewayApisUrlProd: 'https://portal.tcbpay.com',
  //   siteBaseUrl: siteBaseUrl,
  //   blogUrl: 'https://www.tcbpay.com/blog',
  //   gCaptchaSiteKey: '6LcekHAqAAAAAPqhuZVPfqFzT-IwVDp8iUPLZ8k8',
  //   gCaptchaSecretKey: '6LcekHAqAAAAAMxkXUTvtuj8h_JoEBkk84kv-c5a'
  // };
  
  export default config;