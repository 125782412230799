import React, { useEffect, forwardRef } from 'react';
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import config from '../Config';

export const Pricingtable = forwardRef((props: any, ref: React.Ref<HTMLDivElement>) => {
  return (
    <>
        <section className="featuresbx p-0">
            <div className="container">
                <div className='row'>
                    <div className='col-md-12'>
                        <h6 className='seamless-heading'>TCB Pay in a nutshell</h6>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-3 col-md-3 pricing-column-wrapper'>
                        <div className='pricing-column'>
                            <div className='pp-pic'><img src='assets/imgs/pp-icon.svg' alt='' /></div>
                            <div className='pricing-row-title'>Payments</div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Payment Options</div>
                            <div className='listpp'>
                                <ul>
                                    <li>Credit Card Processing</li>
                                    <li><b>ACH Processing</b></li>
                                    <li>QR Codes</li>
                                    <li>Payment links</li>
                                    <li><b>Batch processing</b></li>
                                    <li>Payment terminals</li>
                                </ul>
                            </div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Other Options</div>
                            <div className='listpp'>
                                <ul>
                                    <li>Capture</li>
                                    <li>Authorize</li>
                                    <li>Refund</li>
                                    <li>Void</li>
                                    <li>Credit</li>
                                </ul>
                            </div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Billing</div>
                            <div className='listpp'>
                                <ul>
                                    <li><b>Invoicing</b></li>
                                    <li><b>Recurring billing</b></li>
                                    <li>Plans</li>
                                </ul>
                            </div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Accepted Methods of payment</div>
                            <div className='listpp'>
                                <ul>
                                    <li>Cards</li>
                                    <li>Bank transfers</li>
                                    <li>Digital wallets</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-3 col-md-3 pricing-column-wrapper'>
                        <div className='pricing-column'>
                        <div className='pp-pic'><img src='assets/imgs/pp-icon1.svg' alt='' /></div>
                            <div className='pricing-row-title'>Fraud & Security</div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Fraud Prevention</div>
                            <div className='listpp'>
                                <ul>
                                    <li>Vault</li>
                                    <li>Card data theft protection</li>
                                    <li>PCI Compliance</li>
                                    <li>Encrypted transactions</li>
                                    <li><b>Tokenization</b></li> 
                                    <li>AVS Address verification</li>
                                    <li>3D-Secure</li>
                                    <li>Card ID verification</li>
                                    <li>KYC (Know Your Customer)</li>
                                    <li>AML (Anti-Money Laundering)</li> 
                                    <li><b>Risk rules automation</b></li>
                                    <li><b>ACH Verify</b></li>
                                </ul>
                            </div>
                        </div>

                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Account Security</div>
                            <div className='listpp'>
                                <ul>
                                    <li><b>2-factor authentication</b></li>
                                    <li><b>IP monitoring</b></li>
                                    <li>Notifications & alerts</li>
                                    <li>Change logs</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-3 col-md-3 pricing-column-wrapper'>
                        <div className='pricing-column'>
                        <div className='pp-pic'><img src='assets/imgs/pp-icon2.svg' alt='' /></div>
                            <div className='pricing-row-title'>Convenience</div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Transaction Management</div>
                            <div className='listpp'>
                                <ul>
                                    <li><b>Multiple MIDs</b></li>
                                    <li><b>Transaction Routing</b></li>
                                    <li>100+ currencies</li>
                                    <li>Next-day funding availability</li>
                                </ul>
                            </div>
                        </div>

                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Mobile</div>
                            <div className='listpp'>
                                <ul>
                                    <li>Mobile-friendly view </li>
                                    <li>IOS and Android apps linked to the account</li>
                                </ul>
                            </div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Integrations</div>
                            <div className='listpp'>
                                <ul>
                                    <li><b>Easy API integration</b></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-3 col-md-3 pricing-column-wrapper'>
                        <div className='pricing-column'>
                        <div className='pp-pic'><img src='assets/imgs/pp-icon3.svg' alt='' /></div>
                            <div className='pricing-row-title'>Operations</div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>User Access</div>
                            <div className='listpp'>
                                <ul>
                                    <li>Multi-user capabilities</li>
                                    <li>Permission management</li>
                                </ul>
                            </div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Transparency</div>
                            <div className='listpp'>
                                <ul>
                                    <li>100% transparency in pricing</li>
                                    <li><b>White-label offerings</b></li>
                                    <li>Customized dashboard</li>
                                </ul>
                            </div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Management Tools</div>
                            <div className='listpp'>
                                <ul>
                                    <li>Customer database management</li> 
                                    <li>Automatic card updater</li>
                                </ul>
                            </div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Support</div>
                            <div className='listpp'>
                                <ul>
                                    <li><b>24/7 customer service</b></li> 
                                    <li>Email, text, and phone support</li>
                                </ul>
                            </div>
                        </div>
                        <div className='pricing-listbx'>
                            <div className='eachtitle'>Reports</div>
                            <div className='listpp'>
                                <ul>
                                    <li>Real-time transactions</li>  
                                    <li>Chargebacks</li> 
                                    <li>Settlements</li> 
                                    <li>Cross-Processors</li> 
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="accordionpp" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div className='pricing-column'>
                            <div className='pp-pic'><img src='assets/imgs/pp-icon.svg' alt='' /></div>
                            <div className='pricing-row-title'>Payments</div>
                            </div>
                        </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Payment Options</div>
                                <div className='listpp'>
                                <ul>
                                <li>Credit Card Processing</li>
                                <li><b>ACH Processing</b></li>
                                <li>QR Codes</li>
                                <li>Payment links</li>
                                <li><b>Batch processing</b></li>
                                <li>Payment terminals</li>
                                </ul>
                                </div>
                                </div>
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Other Options</div>
                                <div className='listpp'>
                                <ul>
                                <li>Capture</li>
                                <li>Authorize</li>
                                <li>Refund</li>
                                <li>Void</li>
                                <li>Credit</li>
                                </ul>
                                </div>
                                </div>
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Billing</div>
                                <div className='listpp'>
                                <ul>
                                <li><b>Invoicing</b></li>
                                <li><b>Recurring billing</b></li>
                                <li>Plans</li>
                                </ul>
                                </div>
                                </div>
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Accepted Methods of payment</div>
                                <div className='listpp'>
                                <ul>
                                <li>Cards</li>
                                <li>Bank transfers</li>
                                <li>Digital wallets</li>
                                </ul>
                                </div>
                                </div>
                        </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div className='pricing-column'>
                            <div className='pp-pic'><img src='assets/imgs/pp-icon1.svg' alt='' /></div>
                            <div className='pricing-row-title'>Fraud & Security</div>
                            </div>
                        </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Fraud Prevention</div>
                                <div className='listpp'>
                                <ul>
                                <li>Vault</li>
                                <li>Card data theft protection</li>
                                <li>PCI Compliance</li>
                                <li>Encrypted transactions</li>
                                <li><b>Tokenization</b></li> 
                                <li>AVS Address verification</li>
                                <li>3D-Secure</li>
                                <li>Card ID verification</li>
                                <li>KYC (Know Your Customer)</li>
                                <li>AML (Anti-Money Laundering)</li> 
                                <li><b>Risk rules automation</b></li>
                                <li><b>ACH Verify</b></li>
                                </ul>
                                </div>
                                </div>

                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Account Security</div>
                                <div className='listpp'>
                                <ul>
                                <li><b>2-factor authentication</b></li>
                                <li><b>IP monitoring</b></li>
                                <li>Notifications & alerts</li>
                                <li>Change logs</li>
                                </ul>
                                </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <div className='pricing-column'>
                            <div className='pp-pic'><img src='assets/imgs/pp-icon2.svg' alt='' /></div>
                            <div className='pricing-row-title'>Convenience</div>
                            </div>
                        </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Transaction Management</div>
                                <div className='listpp'>
                                <ul>
                                <li><b>Multiple MIDs</b></li>
                                <li><b>Transaction Routing</b></li>
                                <li>100+ currencies</li>
                                <li>Next-day funding availability</li>
                                </ul>
                                </div>
                                </div>

                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Mobile</div>
                                <div className='listpp'>
                                <ul>
                                <li>Mobile-friendly view </li>
                                <li>IOS and Android apps linked to the account</li>
                                </ul>
                                </div>
                                </div>
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Integrations</div>
                                <div className='listpp'>
                                <ul>
                                <li><b>Easy API integration</b></li>
                                </ul>
                                </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <div className='pricing-column'>
                            <div className='pp-pic'><img src='assets/imgs/pp-icon3.svg' alt='' /></div>
                            <div className='pricing-row-title'>Operations</div>
                            </div>
                        </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>User Access</div>
                                <div className='listpp'>
                                <ul>
                                <li>Multi-user capabilities</li>
                                <li>Permission management</li>
                                </ul>
                                </div>
                                </div>
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Transparency</div>
                                <div className='listpp'>
                                <ul>
                                <li>100% transparency in pricing</li>
                                <li><b>White-label offerings</b></li>
                                <li>Customized dashboard</li>
                                </ul>
                                </div>
                                </div>
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Management Tools</div>
                                <div className='listpp'>
                                <ul>
                                <li>Customer database management</li> 
                                <li>Automatic card updater</li>
                                </ul>
                                </div>
                                </div>
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Support</div>
                                <div className='listpp'>
                                <ul>
                                <li><b>24/7 customer service</b></li> 
                                <li>Email, text, and phone support</li>
                                </ul>
                                </div>
                                </div>
                                <div className='pricing-listbx'>
                                <div className='eachtitle'>Reports</div>
                                <div className='listpp'>
                                <ul>
                                <li>Real-time transactions</li>  
                                <li>Chargebacks</li> 
                                <li>Settlements</li> 
                                <li>Cross-Processors</li> 
                                </ul>
                                </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    </div>

                </div>
            </div>
            
        </section>
        
        <section ref={ref} className='pp-contentbx'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-10 position-relative'>
                        <img src="assets/imgs/blue-dots.svg" alt="" className="blue-dots4" />
                        <div className='pp-contenttitle'><img src='assets/imgs/pp-icon4.svg' alt='' /> Payments</div>
                        <div className='pp-contentbxin flex-startnw'>
                            <ul className='border-radiuscustm1'>
                                <li className='border-radiuscustm2'>
                                    <div className='pp-listtxt text-left'>
                                    <h6>Card-Present</h6>
                                    <p>This surcharge is automatically added to the transaction, which means that you do not have to pay any monthly fees.</p>
                                        <div className='listpp1'>
                                            <ul>
                                                <li>International cards included</li>
                                                <li>Currency conversion included</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>2.75% + $0.20
                                    <span>per transfer</span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div className='pp-listtxt text-left'>
                                    <h6>Card-not-present</h6>
                                    <p>This surcharge is automatically added to the transaction, which means that you do not have to pay any monthly fees.</p>
                                        <div className='listpp1'>
                                            <ul>
                                                <li>International cards included </li>
                                                <li>Currency conversion included</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>3.5% + $0.20
                                    <span>per transfer</span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div className='pp-listtxt text-left'>
                                    <h6>ACH</h6>
                                    <p>Accept electronic payments by bank debits.</p>
                                    </div>
                                </li>
                                <li>10 cts
                                    <span>per transfer</span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div className='pp-listtxt text-left'>
                                    <h6>3D-Secure</h6>
                                    <p>Authentication step for online card transactions to validate the online shopper as the cardholder.</p>
                                    </div>
                                </li>
                                <li>6 cents
                                    <span>per attempt</span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div className='pp-listtxt text-left'>
                                    <h6>AVS</h6>
                                    <p>Automatically decline transactions if the cardholder’s address cannot be verified or if there’s a mismatch.</p>
                                    </div>
                                </li>
                                <li>3 cents
                                    <span>per verification</span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div className='pp-listtxt text-left'>
                                    <h6>Chargebacks</h6>
                                    <p>Manage your disputes.</p>
                                    </div>
                                </li>
                                <li>
                                    25 dollars
                                    <span>per chargeback</span>
                                </li>
                            </ul>
                            <ul className='border-radiuscustm'>
                                <li className='border-radiuscustm3'>
                                    <div className='pp-listtxt text-left'>
                                    <h6>QR code</h6>
                                    <p>Create QR codes with a custom or fixed amount. Customizable with your logo.</p>
                                    </div>
                                </li>
                                <li >Included</li>
                            </ul>
                        </div>
                    </div>
                </div> 
            </div>
        </section>

        <section className='pp-contentbx'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-10 position-relative'>
                        <div className='pp-contenttitle'><img src='assets/imgs/pp-icon5.svg' alt='' /> Billing</div>
                        <div className='pp-contentbxin'>
                            <ul className='border-radiuscustm1'>
                                <li className='border-radiuscustm2'>
                                    <div className='pp-listtxt text-left'>
                                    <h6>Invoices</h6>
                                    <p>Create, send, schedule, and monitor invoices</p>
                                    </div>
                                </li>
                                <li>Included</li>
                            </ul>
                            <ul>
                                <li>
                                    <div className='pp-listtxt text-left'>
                                    <h6>Share payment link by email</h6>
                                    <p>This surcharge is automatically added to the transaction, which means that you do not have to pay any monthly fees.</p>
                                    </div>
                                </li>
                                <li>Included</li>
                            </ul>
                            <ul className='border-radiuscustm'>
                                <li border-radiuscustm3>
                                    <div className='pp-listtxt text-left'>
                                    <h6>Recurring Billing</h6>
                                    <p>Automate your billing cycles with our reliable recurring payment system for subscription-based services.</p>
                                    </div>
                                </li>
                                <li>Included</li>
                            </ul>

                        </div>
                        <img src="assets/imgs/orng-dots1.svg" alt="" className="orng-dots3" />
                    </div>
                </div> 
            </div>
        </section>

        <section className='pp-contentbx pb-180'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-10'>
                        <div className='pp-contenttitle'><img src='assets/imgs/pp-icon6.svg' alt='' /> Issuing</div>
                        <div className='pp-contentbxin'>
                            <ul className='border-radiuscustm1'>
                                <li className='border-radiuscustm2'>
                                    <div className='pp-listtxt text-left'>
                                    <h6>Account setup</h6>
                                    <p>Onboarding process, from the account approval process to your first corporate credit card.</p>
                                    </div>
                                </li>
                                <li>Free</li>
                            </ul>
                            <ul>
                                <li>
                                    <div className='pp-listtxt text-left'>
                                    <h6>TCB Pay Issuing platform</h6>
                                    <p>Gain complete access to our platform to effortlessly manage all your business-related expenses.</p>
                                    <div className='listpp1'>
                                            <ul>
                                                <li>International cards included </li>
                                                <li>Currency conversion included</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>No monthly fee</li>
                            </ul>
                            <ul>
                                <li>
                                    <div className='pp-listtxt text-left'>
                                    <h6>Card creation</h6>
                                    <p>Get digital cards instantly or order physical cards in just a few days. <i>For physical cards, shipping & handling fees might apply. Virtual cards are 100% free.</i></p>
                                    </div>
                                </li>
                                <li>Free</li>
                            </ul>
                            <ul>
                                <li>
                                    <div className='pp-listtxt text-left'>
                                    <h6>Chargebacks</h6>
                                    <p>Manage your disputes.</p>
                                    </div>
                                </li>
                                <li>
                                    25 dollars
                                    <span>per chargeback</span>
                                </li>
                            </ul>
                            <ul className='border-radiuscustm'>
                                <li className='border-radiuscustm3'>
                                    <div className='pp-listtxt text-left'>
                                    <h6>International payments</h6>
                                    <p>Use your card when abroad at no extra cost.</p>
                                    </div>
                                </li>
                                <li>Included</li>
                            </ul>
                        </div>
                    </div>
                </div> 
            </div>
        </section>

        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />
    </>
  );
});
