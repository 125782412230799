import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Animations from '../animations/animation1';
import RunningRectangle from '../animations/animation1';
import OurIntegrations from '../animations/animation1';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import config from '../Config';

export const Highrisk = () => {
    return (
        <>
            <section className='highrisk-specialistsbx'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='highrisk-inner'>
                                <h6><span>High-risk</span> specialists</h6>
                                <p>Unlock new posibilities of your high-risk bussiness with TCB Pay's approved services. Our specialized solutions are tailored to meet the unique needs of high-risk-industries, providing
                                    reliable payment processing and risk management.
                                </p>
                                <Link to="/high-risk" className="highrisk-inner-btn"><img src={`${config.siteBaseUrl}/assets/imgs/arw-agn.svg`} alt=''/> Discover our high-risk industries </Link>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='highrisk-inner-pic order-none'>
                                <ul>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img1.svg`} alt="" /><span className='t'>CBD/Hemp/Vape</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img2.svg`} alt="" /><span className='t'>Credit Repair</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img3.svg`} alt="" /><span className='t'>Cryptocurrency</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img4.svg`} alt="" /><span className='t'>Digital Goods</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow even'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img5.svg`} alt="" /><span className='t'>Electronics</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img6.svg`} alt="" /><span className='t'>Gaming</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img7.svg`} alt="" /><span className='t'>Pharma/Nutra</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img8.svg`} alt="" /><span className='t'>Tech Support</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img9.svg`} alt="" /><span className='t'>Telecommunication</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img10.svg`} alt="" /><span className='t'>Telemedicine</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img11.svg`} alt="" /><span className='t'>Travel</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img12.svg`} alt="" /><span className='t'>Warranties</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='highrisk-inner-pic order-block'>
                                <ul>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img1.svg`} alt="" /><span className='t'>CBD/Hemp/Vape</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img2.svg`} alt="" /><span className='t'>Credit Repair</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img3.svg`} alt="" /><span className='t'>Cryptocurrency</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img4.svg`} alt="" /><span className='t'>Digital Goods</span>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img6.svg`} alt="" /><span className='t'>Gaming</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow even'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img5.svg`} alt="" /><span className='t'>Electronics</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img8.svg`} alt="" /><span className='t'>Tech Support</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img7.svg`} alt="" /><span className='t'>Pharma/Nutra</span>
                                        </div>
                                    </li>
                                    

                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img9.svg`} alt="" /><span className='t'>Telecommunication</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img10.svg`} alt="" /><span className='t'>Telemedicine</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img11.svg`} alt="" /><span className='t'>Travel</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='yellow'>
                                            <img src={`${config.siteBaseUrl}/assets/imgs/high-risk-img12.svg`} alt="" /><span className='t'>Warranties</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    <section className='our-integrationsbx pb-0'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-8 m-auto'>
                    <div className='our-integration-inner text-center'>
                        <h6 className='text-center'>Our <span>integrations</span></h6>
                        <p className='text-center'>Experience effortless integration with TCB Pay's comprehensive suite of payment solutions.</p>
                        <p className='text-center'> Our platform offers seamless integration capabilities, allowing you to connect with various third-party systems and applications to enhance your business operations. TCB Pay's integrations provide the flexibility and scalability you need to thrive in today's digital economy.</p>
                        <Link to="/integrations" className="integration-btn text-center">Discover our integrations <FontAwesomeIcon icon={faArrowRight} /> </Link>
                    </div>    
                </div>
                <div className='col-md-12'>
                   <div className='our-integration-pic'>
                        <div className="marquee marquee--8">
                        <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo1.svg`} alt="" />
                        <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo2.svg`} alt="" />
                        <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo3.svg`} alt="" />
                        <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo4.svg`} alt="" />
                        <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo5.svg`} alt="" />
                        <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo6.svg`} alt="" />
                        <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo7.svg`} alt="" />
                        <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo8.svg`} alt="" />
                        <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo9.svg`} alt="" />
                        <img className="marquee__item" src={`${config.siteBaseUrl}/assets/imgs/logo/our-logo10.svg`} alt="" />
                    </div>
                   </div>
                </div>
                {/* <div className='col-md-12'>
                    <div className='our-integration-pic'>
                        <RunningRectangle/>
                    </div>    
                </div>  */}
                    </div>
                </div>
    </section>

            {/* <section style={{padding:'200px 0'}}>

                <RunningRectangle />

            </section> */}
        </>
    )
}
