import React from 'react';
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = () => {

    const navigate = useNavigate();
  return (
    <>
    <section className='banner-bg min-heght'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <div className='text-left col-md-5'>
            <span><img src='assets/imgs/tcbportalicon.svg' alt='' /> TCB PAY PORTAL</span>
            <h2>Your one-stop-shop payment solution</h2>
            <p>Our custom-built payment processing solution offers unmatched simplicity and efficiency.</p> 
            <p>Accessible from any device at any time, enjoy the flexibility to accept payments in multiple currencies, and with multiple MIDs consolidated into one single account.</p>
            <Link to={`${config.siteBaseUrl}#show-calendly`} className="get-btn1">Get Started</Link>
            <Link to="/contact" className="contact-btn">Contact Us</Link>
            </div>
            <div className='col-md-7'>
            <img src='assets/imgs/portal-banner.svg' alt='' className='banner2'/>
            </div>
          </Row>    
        </Container>

    </section>
    
    </>
  )
}
