import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';


export const IntrigrationSec = () => {

  const exampleCodeText_1 = `<form name="" id="formID" action="${config.gatewayApisUrlProd}/apis/authorize.php" method="post">
  <input type="text" name="username" value=" tcbpayusername"/>
  <input type="text" name="api_key" value=" tcbpayapikey"/>
  <input type="text" name="card_token" id="card_token" value="XXXXXXXXXXXXXXXXXXXXXXXXX"/>
  <input type="text" name="cvv" value="123"/>
  <input type="text" name="amount" value="14.5"/>
  <input type="text" name="currency" value="USD"/>
  <input type="text" name="company_name" value="TCB Pay"/>
  <input type="text" name="first_name" value="Roberto"/>
  <input type="text" name="last_name" value="Diaz"/>
  <input type="text" name="address" value="4446 Green Avenue, Suite 56"/>
  <input type="text" name="city" value="Los Angeles"/>
  <input type="text" name="state" value="CA"/>
  <input type="text" name="country_code" value="US"/>
  <input type="text" name="zip_code" value="11111"/>
  <input type="text" name="phone_number" value="323-323-3223"/>
  <input type="text" name="email" value="abcdef@mail.com"/>
  <input type="checkbox" name="email_receipt"  value="Y"/>
  <br/>
  <input type="button" value="submit"  />
</form>`;
  const exampleCodeText_2 = `{"response":{"code":"01","description":" Merchant Information is Missing"}}`;

  const onCopy = React.useCallback((id:number) => {
    const element = document.getElementById("myTooltip"+id);
    if (element) {
      element.classList.add("show");
      const intervalId = setInterval(() => {
        element.classList.remove("show");
        clearInterval(intervalId);
      }, 2000);
    }
  }, []);

  return (
    <>
    <section className="credit-api-main">
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <IntrigrationLeftSec />
                </div>
                <div className="col-lg-9">
                    <div className="creadit-api-upper-wrapper">
                          <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                        <h1>Vault API</h1>

                        <ul className="card-url">
                            <li>
                                <div className="card-url-list">
                                     <p>Sandbox endpoint URL:</p>
                                     <span><Link to=''>{`${config.gatewayApisUrlSand}/apis/{method}`}</Link></span>
                                </div>
                              
                            </li>
                            
                            <li>
                                <div className="card-url-list">
                                    <p>Example:</p>
                                    <span><Link to=''>{config.gatewayApisUrlSand}/apis/authorize.php</Link></span>
                                </div>
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Production endpoint URL:</p>
                                    <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="creadit-api-lower-wrapper">
                        <div className="api-menu-item-title">
                            <h2>Authorize</h2>
                        </div>
                        <div className="api-menu-item-wrapper">
                            <h3>Authorize API</h3>
                            <hr />
                            <p>This API is responsible to perform pre-authorization payment.</p>
                            <ul className="card-url api-menu-url pb-40">
                                <li>
                                    <div className="card-url-list">
                                         <p>URL to call</p>
                                         <span><Link to=''>{config.gatewayApisUrlSand}/apis/authorize.php</Link></span>
                                    </div>
                                  
                                </li>
                                <li>
                                    <div className="card-url-list">
                                        <p>Method of form submission</p>
                                        <span><Link to=''>POST</Link></span>
                                   </div>
                                </li>
                              
                            </ul>
                        </div>
                    </div>

                    <div className="api-table">
                        <h5>This table describes the request parameters for Authorize API. </h5>

                              <div className="table-responsive">
                                  <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <th>Variable Name</th>
                                        <th>Description</th>
                                        <th>Required</th>
                                        <th>Expected Values</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>username</td>
                                        <td>TCB Pay Gateway Username</td>
                                        <td>Y</td>
                                        <td>TCB Pay Gateway Username</td>
                                      </tr>
                                      <tr>
                                        <td>api_key</td>
                                        <td>TCB Pay Gateway API Key</td>
                                        <td>Y</td>
                                        <td>TCB Pay Gateway API Key</td>
                                      </tr>
                                      <tr>
                                        <td>card_token</td>
                                        <td>Get a token during Add Card Vault API. </td>
                                        <td>C</td>
                                        <td>XXXXXXXXXXXXXXXXXXXXXXXXX</td>
                                      </tr>
                                      <tr>
                                        <td>cvv</td>
                                        <td>CVV of the Credit Card</td>
                                        <td>Y</td>
                                        <td>123</td>
                                      </tr>
                                      <tr>
                                        <td>amount</td>
                                        <td>Amount to be charged</td>
                                        <td>Y</td>
                                        <td>133.24</td>
                                      </tr>
                                      <tr>
                                        <td>currency</td>
                                        <td>Currency of Transaction (allowed currencies will be determined during the time of merchant boarding).</td>
                                        <td>Y</td>
                                        <td>USD,EUR,GBP,CAD,AUD,NZD,<br/>AED,DKK,MXN,SEK,CHF</td>
                                      </tr>
                                      <tr>
                                        <td>company_name</td>
                                        <td>Company Name of the customer</td>
                                        <td>N</td>
                                        <td>-</td>
                                      </tr>
                                      <tr>
                                        <td>first_name</td>
                                        <td>First name of Account Holder</td>
                                        <td>Y</td>
                                        <td>Roberto</td>
                                      </tr>
                                      <tr>
                                        <td>last_name</td>
                                        <td>Last name of Account Holder</td>
                                        <td>Y</td>
                                        <td>Diaz</td>
                                      </tr>
                                      <tr>
                                        <td>address</td>
                                        <td>Address of the customer</td>
                                        <td>N</td>
                                        <td>4446 Green Avenue, Suite 56</td>
                                      </tr>
                                      <tr>
                                        <td>city</td>
                                        <td>City of the customer</td>
                                        <td>N</td>
                                        <td>Los Angeles</td>
                                      </tr>
                                      <tr>
                                        <td>state</td>
                                        <td>State of the customer</td>
                                        <td>N</td>
                                        <td>CA</td>
                                      </tr>
                                      <tr>
                                        <td>country_code</td>
                                        <td>Country of the customer(ISO alpha-2)</td>
                                        <td>Y</td>
                                        <td>US, CN</td>
                                      </tr>
                                      <tr>
                                        <td>zip_code</td>
                                        <td>Zip code of the customer</td>
                                        <td>Y</td>
                                        <td>94085</td>
                                      </tr>
                                      <tr>
                                        <td>phone_number</td>
                                        <td>Phone Number of the customer</td>
                                        <td>N</td>
                                        <td>408-345-2323</td>
                                      </tr>
                                      <tr>
                                        <td>e-mail</td>
                                        <td>Email ID of the customer</td>
                                        <td>Y</td>
                                        <td>test@test.com</td>
                                      </tr>
                                      <tr>
                                        <td>e-mail receipt</td>
                                        <td>Send transaction details to a specified email </td>
                                        <td>N</td>
                                        <td>Y or N (default value N) <br/>
                                          Y- Send email <br/>
                                          N- Not send</td>
                                      </tr>
                                      <tr>
                                        <td>client_ip</td>
                                        <td>Client IP address</td>
                                        <td>N</td>
                                        <td>127.0.0.1</td>
                                      </tr>
                                      <tr>
                                        <td>reference_id</td>
                                        <td>Transaction reference id</td>
                                        <td>N</td>
                                        <td>Reference ID should be unique</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                        <div className="example-box-wrapper">
                            <div className="d-flex align-items-start">
                                <b></b>
                               <p><span>Example</span></p>
                            </div>
                            <div className="example-box">
                                <div className="example-box-top">
                                <div className="tooltip1" id="myTooltip1">
                                  <span className="tooltiptext">Copied</span>
                                </div>
                                <CopyToClipboard onCopy={() => onCopy(1)} text={exampleCodeText_1}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </CopyToClipboard>
                                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                </div>
                                <div className="ex-text-box bighgt">
                                    <pre>{exampleCodeText_1}</pre>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                        <div className="api-menu-item-wrapper mt-60">
                            <h3>Response</h3>
                            <hr />
                            <p>The response of the API Call is a JSON array. For Error, two elements are returned, code and description. For Successful transactions, three elements are returned, code, description, and transaction_id. The response code for a successful API call is always 00.</p>
                            <div className="example-box-wrapper mb-20">
                                <div className="d-flex align-items-start">
                                    <b></b>
                                    <span>Sample Response</span>
                                </div>
                                <div className="example-box">
                                    <div className="example-box-top">
                                    <div className="tooltip1" id="myTooltip2">
                                      <span className="tooltiptext">Copied</span>
                                    </div>
                                    <CopyToClipboard onCopy={() => onCopy(2)} text={exampleCodeText_2}>
                                      <FontAwesomeIcon icon={faCopy} />
                                    </CopyToClipboard>
                                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                                    </div>
                                    <div className="ex-text-box">
                                        <pre>{exampleCodeText_2}</pre>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                 
                     


                </div>
            </div>
        </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />

        <ScrollToTop smooth />
    </section>
    </>
  )
}



