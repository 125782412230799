import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = ({ scrollRef }: { scrollRef: React.RefObject<HTMLDivElement> }) => {
    const scrollToMiddle = () => {
        scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      };
  return (
    <>
    <section className='banner-bg bg-transparentcustm it-mb'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <div className='text-left col-md-12'>
            <span><img src='assets/imgs/it-icon.svg' alt='' />IT SOLUTIONS</span>
            <h2>Comprehensive IT solutions by TCB Pay</h2>
            </div>
            <div className='text-left col-md-7'>
            <p>We provide digital transformation services and IT solutions. We are dedicated to ensuring complete customer satisfaction in the fintech industry by creating, developing, and implementing cutting-edge software, web, and mobile services for innovative global businesses of all sizes.</p>
            <button className="get-btn2" onClick={scrollToMiddle}>Get Started</button>
            {/* <Link className="contact-btn1" to="/contact">Contact Us</Link> */}
            </div>
          </Row>  
        </Container>
    </section>
    </>
  )
}
