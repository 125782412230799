import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink  } from 'react-router-dom';
import { IntrigrationLeftSec } from '../components/intrigrationLeftSec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from "react-scroll-to-top";
import config from '../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';


export const IntrigrationSec = () => {

  const exampleCodeText_1 = `<script type="text/javascript" src="https://[server-name]/services/proxynization_api.js"></script>`;
  const exampleCodeText_2 = `<script type="text/javascript" >
    function tcbpayClientCallback(responseCode, responseMessage, proxyNumber){
      if(responseCode == "A01"){
      document.getElementById('creditcard_number').value = proxyNumber;
      document.getElementById("formID").submit();
      } else{
      alert(responseMessage);
      return false;
      }
    }
  </script>
  `;
  const exampleCodeText_3 = `<input type="button" value="submit" onClick="TCBPayProxynizationAPI.process('#creditcard_number','tcbpayClientCallback');" />`;
  const exampleCodeText_4 = `  <form name="" id="formID" action="${config.gatewayApisUrlProd}/apis/sale.php" method="post">
  <input type="text" name="username" value="tcbpayusername"/>
  <input type="text" name="api_key" value="tcbpayapikey"/>
  <input type="text" name="creditcard_number" id="creditcard_number" value="4111111111111111"/>
  <input type="text" name="expire_month" value="06"/>
  <input type="text" name="expire_year" value="2025"/>
  <input type="text" name="cvv" value="123"/>
  <input type="text" name="amount" value="14.5"/>
  <input type="text" name="currency" value="USD"/>
  <input type="text" name="company_name" value="TCB Pay"/>
  <input type="text" name="first_name" value="Roberto"/>
  <input type="text" name="last_name" value="Diaz"/>
  <input type="text" name="address" value="4446 Green Avenue, Suite 56"/>
  <input type="text" name="city" value="Los Angeles"/>
  <input type="text" name="state" value="CA"/>
  <input type="text" name="country_code" value="US"/>
  <input type="text" name="zip_code" value="11111"/>
  <input type="text" name="phone_number" value="323-323-3223"/>
  <input type="text" name="client_ip" value="127.0.0.1"/>
  <input type="text" name="email" value="abcdef@mail.com"/>
  <input type="checkbox" name="email_receipt"  value="Y"/>
  <br/>
  <input type="button" value="submit" onClick="TCBPayProxynizationAPI.process('#creditcard_number','tcbpayClientCallback');" />
</form>

<script type="text/javascript" >
  function tcbpayClientCallback(responseCode, responseMessage, proxyNumber){
    if(responseCode == "A01"){
    document.getElementById('creditcard_number').value = proxyNumber;
    document.getElementById("formID").submit();
    } else{
    alert(responseMessage);
    return false;
    }
  }
</script>`;
  const exampleCodeText_5 = `{"response":{"code":"00","description":" Transaction Entry Success","transaction_id":102," total_amount":15.7," convenience_fee":1.2}}`;
  const exampleCodeText_6 = `{"response":{"code":"00","description":"Transaction pending cardholder authentication","transaction_id":102,"redirect_url":"https://[server-name]/challenge_request.php?uid=xxxxxxxxx"}}`;

  const onCopy = React.useCallback((id:number) => {
    const element = document.getElementById("myTooltip"+id);
    if (element) {
      element.classList.add("show");
      const intervalId = setInterval(() => {
        element.classList.remove("show");
        clearInterval(intervalId);
      }, 2000);
    }
  }, []);

  return (
    <>
    <section className="credit-api-main">
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt="" className='apibg' />
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <IntrigrationLeftSec />
                </div>
                <div className="col-lg-9">
                    <div className="creadit-api-upper-wrapper">
                          <Link to="/integrations" className='apidetail-btn'><FontAwesomeIcon icon={faArrowRight} /> Return to Integrations page</Link>
                        <h1>Vault API</h1>

                        <ul className="card-url">
                            <li>
                                <div className="card-url-list">
                                     <p>Sandbox endpoint URL:</p>
                                     <span><Link to=''>{`${config.gatewayApisUrlSand}/apis/{method}`}</Link></span>
                                </div>
                              
                            </li>
                            
                            <li>
                                <div className="card-url-list">
                                    <p>Example:</p>
                                    <span><Link to=''>{config.gatewayApisUrlSand}/apis/add-card-vault.php</Link></span>
                                </div>
                            </li>
                            <li>
                                <div className="card-url-list">
                                    <p>Production endpoint URL:</p>
                                    <span><Link to=''>{`${config.gatewayApisUrlProd}/apis/{method}`}</Link></span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="creadit-api-lower-wrapper">
                        <div className="api-menu-item-title">
                            <h2>Sandbox</h2>
                        </div>
                    </div>

                    <div className="api-table">
                        <h5>Test Credit Card Details</h5>

                                <div className="table-responsive">
                                  <table summary="The table describes the request parameters to perform a sale." className="table table-striped table-bordered table-hover">
                                    <thead>
                                      <tr>
                                        <th>Card Number</th>
                                        <th>CVV</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>4005510000000013</td>
                                        <td>123</td>
                                      </tr>
                                      <tr>
                                        <td>4111111111111111</td>
                                        <td>111</td>
                                      </tr>
                                      <tr>
                                        <td>4012001037141112</td>
                                        <td>083</td>
                                      </tr>
                                      <tr>
                                        <td>5111111111111111</td>
                                        <td>123</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                        
                    </div>

                  
                </div>
            </div>
        </div>
        <img src={`${config.siteBaseUrl}/assets/imgs/blue-lowerdots.svg`}  alt="" className="orng-dots" />

        <ScrollToTop smooth />
    </section>
    </>
  )
}



