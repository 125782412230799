import React from 'react'
import { LegalSec } from './legalSec'

export const Legal = () => {
  return (
   <>
   <LegalSec />
   </>
  )
}
