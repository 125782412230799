import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BsFillChatFill } from "react-icons/bs";
import { BsTelephoneFill } from "react-icons/bs";
import config from '../Config';
{/* <BsTelephoneFill />
<BsFillChatFill /> */}


export const Footer = () => {
  return (
   <>
   
    <footer className="footer-mainbg">
        <div className="footer-container">
            <div className="container pb-80 pt-80 ontop">
                <div className="querybx">
                    <p><b>Any questions? We can help.</b></p>
                    <p>Tap to chat with our customer support team now.</p>
                    <div className="query-icon">
                        <Link to="mailto:support@tcbpay.com" target='blank'><img src={`${config.siteBaseUrl}/assets/imgs/footer-icon1.svg`} alt="" /></Link>
                        <Link to="tel:+1(866)444-8585"><img src={`${config.siteBaseUrl}/assets/imgs/footer-icon2.svg`} alt="" /></Link>
                        <Link to="tel:+1(866)444-8585"><img src={`${config.siteBaseUrl}/assets/imgs/footer-icon3.svg`} alt="" /></Link>
                    </div>
                </div>
                <div className='row mt-50 mb-50 align-items-center'>
                    <div className='col-lg-3'>
                        <div className="logo icon-img-170">
                            <img src={`${config.siteBaseUrl}/assets/imgs/logo-light.svg`} alt="Logo" />
                        </div>
                    </div>
                    <div className='col-lg-9'>
                        <div className="share-icon d-flex justify-content-end">
                            <Link to="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F6417575%2Fadmin%2F" target='blank'><FaLinkedinIn /></Link>
                            <Link to="https://instagram.com/tcbpay?igshid=ky6kgcgfmlh0" target='blank'><FaInstagram /></Link>
                            <Link to="https://www.youtube.com/@TCBPay?app=desktop" target='blank'><FaYoutube /></Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 md-mb50">
                        <div className="tit mb-20">
                            <h6>Products</h6>
                        </div>
                        <ul className="rest social-text">
                            <li>
                                <Link to="portal">TCB Pay Portal</Link>
                            </li>
                            <li>
                                <Link to="app">TCB Pay App</Link>
                            </li>
                            <li>
                                <Link to="issuing">TCB Pay Issuing</Link>
                            </li>
                            <li>
                                <Link to="white-label">TCB Pay White Label</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 md-mb50">
                        <div className="tit mb-20">
                            <h6>Services</h6>
                        </div>
                        <ul className="rest social-text">
                            <li>
                                <Link to="retail">Retail</Link>
                            </li>
                            <li>
                                <Link to="ecommerce">Ecommerce</Link>
                            </li>
                            <li>
                                <Link to="high-risk">High-Risk</Link>
                            </li>
                            <li>
                                <Link to="integrations">Integrations</Link>
                            </li>
                            <li>
                                <Link to="it-solutions">IT Solutions</Link>
                            </li>
                            <li>
                                <Link to="ach-processing">ACH Processing</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 md-mb50">
                        <div className="tit mb-20">
                            <h6>Resources</h6>
                        </div>
                        <ul className="rest social-text">
                            <li>
                                <Link to="about-us">About Us</Link>
                            </li>
                            <li>
                                <Link to="our-team">Our Team</Link>
                            </li>
                            <li>
                                <Link to="careers">Careers</Link>
                            </li>
                            <li>
                                <Link to='https://www.tcbpay.com/blog/' target='blank'>Blog</Link>
                            </li>
                            <li>
                                <Link to="partners">Partners</Link>
                            </li>
                            <li>
                                <Link to="security">Security</Link>
                            </li>
                            <li>
                                <Link to="faq">FAQ</Link>
                            </li>
                            <li>
                                <Link to="pricing">Pricing</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 md-mb50">
                        <div className="tit mb-20">
                            <h6>Merchants</h6>
                        </div>
                        <ul className="rest social-text">
                            <li>
                                <Link to="https://issuing.tcbpay.com/" target='blank'>Issuing Login </Link>
                            </li>
                            <li>
                                <Link to="https://portal.tcbpay.com/" target='blank'>Portal Login</Link>
                            </li>
                            <li>
                                <Link to="https://portal.tcbpay.com/app/signup" target='blank'>Open an Account</Link>
                            </li>
                            <li>
                                <Link to="contact">Contact Us</Link>
                            </li> 
                            <li>
                                <Link to="system-status">System Status</Link>
                            </li>
                            <li>
                                <Link to="wiki-issuing">Issuing Wiki</Link>
                            </li>
                            <li>
                                <Link to="wiki-portal">Portal Wiki</Link>
                            </li>
                             
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mid-footer pt-20 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ml-auto d-flex">
                                <p>TCB Pay is a registered <b>ISO</b> of Citizens Bank, N.A., Concord, CA and Fresno First Bank, Fresno, CA. ● TCB Pay is a registered <b>ISO/SP</b> of Westamerica Bank, Santa Rosa, CA. ● TCB Pay is a registered <b>PSP</b> of Credorax Bank, Valletta, Malta. ● TCB Pay is a registered <b>Payment Facilitator</b> of Fifth Third Bank, Cincinnati, OH.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="sub-footer pt-20 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 order-sm-2 order-lg-1">
                            <div className="tcblink">
                                <a>©TCB Pay LTD 2024</a>
                            </div>
                        </div>
                        <div className="col-lg-10 order-sm-1 order-lg-2">
                            <div className="copyright d-flex">
                                <div className="ml-auto">
                                    <ul className='footerLast'>
                                        <li><Link to="legal/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="legal/terms-and-conditions">Terms & Conditions</Link></li>
                                        <li><Link to="legal/payfac-terms-and-conditions">PayFac Terms & Conditions</Link></li>
                                        <li><Link to="legal/code-of-conduct">Code of Conduct</Link></li>
                                        <li><Link to="legal/cookies-policy">Cookies Policy</Link></li>
                                        <li><Link to="legal/personal-guarantee">Personal Guarantee</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    </>
    )
}
