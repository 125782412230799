import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';

export const Banner = () => {
  return (
    <>
    <section className='banner-bg bg-transparentcustm'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <Col xs lg="12" className='text-left'>
            <span><img src='assets/imgs/e-icon.svg' alt='' />ECOMMERCE</span>
            <h2>Power your payments with TCB Pay</h2>
            </Col>
            <Col xs lg="7" className='text-left'>
            <p>Our secure payment portal, seamless integrations, and white-label options make payment processing effortless. Elevate your brand and streamline transactions with TCB Pay.</p>
            <Link className="get-btn2" to={`${config.siteBaseUrl}#show-calendly`}>Get Started</Link>
            <Link className="contact-btn1" to="/contact">Contact Us</Link>
            </Col>
          </Row>  
        </Container>
    </section>
    </>
  )
}
