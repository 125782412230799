import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';


export const Banner = () => {
  return (
    <>
    <section className='banner-bg bg-transparentcustm'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row>
            <div className='text-left col-md-12'>
            <span><img src='assets/imgs/ach-icn.svg' alt='' style={{width:'30px'}} />ACH PROCESSING</span>
            <h2>Effortless ACH payments with TCB Pay</h2>
            </div>
            <div className='text-left col-md-7'>
            <p>Whether your business model cannot accept credit card payments or you want to offer an additional payment method to your customers, ACH payments provide a secure and cost-effective way to get paid easily.</p>
            <Link className="get-btn2" to={`${config.siteBaseUrl}#show-calendly`}>Get Started</Link>
            <Link className="contact-btn1" to="/contact">Contact Us</Link>
            </div>
          </Row>  
        </Container>
    </section>
    </>
  )
}
