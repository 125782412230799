import React,{ useRef } from 'react';
import { Route, BrowserRouter as Router, Routes, Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../Config';
import { Calender } from './calender';
import Animation2 from '../animations/animation2';

export const Banner = ({ scrollRef }: { scrollRef: React.RefObject<HTMLDivElement> }) => {

  const scrollToMiddle = () => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };
  
    const navigate = useNavigate();
    const handleNavigation = () => {
      navigate('/#show-calendly'); 
    };
    
  return (
    <>
    <section className='banner-bg'>
      <img src={`${config.siteBaseUrl}/assets/imgs/our-integrations-bg.svg`} alt='' />
      
        <Container>
           <Row className="justify-content-md-center">
            <Col xs lg="9">
            <h2 className='home-pd'>Global payment solutions for all businesses</h2>
            <p className='mb-40'>Whether you run an e-commerce store, retail shop, or an on-the-go<br></br> business, we deliver tailored payment solutions to meet your needs.</p>
            <button onClick={handleNavigation} className="get-btn">Get Started</button>
            </Col>
          </Row>    
        </Container>
        <img src={`${config.siteBaseUrl}/assets/imgs/banner-pic.webp`} alt='' className='leftanimation'/>
        <img src={`${config.siteBaseUrl}/assets/imgs/banner-card.webp`} alt='' className='rightanimation'/>
      
        {/* <Calender prop1={scrollRef} />  */}
    </section>
    </>
  )
}
