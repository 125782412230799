import React from 'react'
import { Route, BrowserRouter as Router, Routes, Link, NavLink, useParams  } from 'react-router-dom';
import config from '../Config';

export const Notfound403Sec = () => {
  return (
    <>
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <div className="notfound-wrap">
                        <h1><img src={`${config.siteBaseUrl}/assets/imgs/403.svg`} alt="403" /></h1>
                    </div>
                </div>

                <div className="page-not-found">
                    <div className="notfound-wrap">
                        <h2>Access forbidden</h2>
                        <p>Sorry, you don’t have permission to access this page.</p>
                        <Link to="">Go Back</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
